import React from "react";
import { Link, useParams } from "react-router-dom";

import { PrimaryButton } from "../../components/shared";

type ErrorRouteParams = {
  code: string;
};

export const ErrorPage: React.FC = () => {
  const errorRouteParams = useParams<ErrorRouteParams>();
  const errorCode = errorRouteParams.code || "404";

  return (
    <div
      className="min-h-screen min-w-full bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
      data-testid="error-page-root-element"
    >
      <div
        className="flex place-content-center mb-20"
        id="logo-container"
        data-testid="logo-container"
      >
        <svg
          width="100 "
          height="105"
          viewBox="0 0 26 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          data-testid="reboot-motion-logo"
        >
          <path
            d="M18.29 19.7266C20.0521 18.7156 21.4338 17.4005 22.4378 15.7813C23.4393 14.1621 23.9388 12.4148 23.9388 10.5342C23.9388 7.41314 22.7725 4.87629 20.4425 2.92631C18.11 0.976326 14.525 0 9.68503 0C6.39669 0 3.16919 0.17339 0.00253589 0.520173V7.95465H9.6039V5.34311C9.6039 4.8016 10.4279 4.53218 10.9096 4.91364L16.6167 9.43781C17.1719 9.87796 17.1719 10.7529 16.6167 11.1931L10.9096 15.7172C10.4279 16.0987 9.6039 15.8293 9.6039 15.2878V12.6762H0V31.9973H7.62126V21.5912C9.05626 21.6499 9.99688 21.6792 10.4456 21.6792C11.0136 21.6792 11.4471 21.6659 11.7463 21.6365L17.3494 32H26L18.29 19.7266Z"
            fill="#FB6A07"
          />
        </svg>
      </div>
      <div className="text-center">
        <h2 className="text-center font-bold text-7xl" data-testid="error-code">
          {errorCode}
        </h2>
        <h3
          className="text-center font-bold text-5xl mt-6"
          data-testid="error-heading"
        >
          {errorCode === "404" && "Page Not Found"}
          {errorCode === "403" && `Not Authorized`}
        </h3>
        {errorCode === "403" && (
          <p className="text-center pt-5" data-testid="error-text">
            You are not authorized to view the requested page, or your token has
            expired.
            <br />
            If you believe you have encountered this error by mistake, please
            contact{" "}
            <a
              href="mailto:support@rebootmotion.com"
              className="text-primary-700"
              data-testid="reboot-support-link"
            >
              support@rebootmotion.com
            </a>
            .
          </p>
        )}
        {errorCode === "404" && (
          <p className="text-center pt-5" data-testid="error-text">
            We&apos;re sorry the page you requested could not be found. Please
            go back to the homepage.
          </p>
        )}
        <PrimaryButton className="mt-10">
          <Link to="/" data-testid="home-link">
            Go back home
          </Link>
        </PrimaryButton>
      </div>
    </div>
  );
};
