import { Field, Formik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

import { useAppDispatch } from "../../redux/hooks";
import { uploadSessionFormSlice } from "../../redux/slices/uploadSessionFormSlice";
import { userSlice } from "../../redux/slices/userSlice";
import { useLoginUserMutation } from "../../services";
import { UserLoginData } from "../../types";
import { ErrorToast, PrimaryButton, SpinnerButton } from "../shared";

export const LoginForm: React.FC = () => {
  const { setCredentials, clearLoginState } = userSlice.actions;
  const { clearUploadSessionFormState } = uploadSessionFormSlice.actions;

  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const [makeLoginRequest, { isLoading }] = useLoginUserMutation();

  const toastError = (message: string) =>
    toast.custom(
      <ErrorToast message={message} classNames={"mt-modal"} support={true} />,
      {
        id: "loginError",
        duration: 6000,
      },
    );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        username: "",
        password: "",
        scope: "",
      }}
      validationSchema={Yup.object({
        username: Yup.string()
          .email("Must be a valid email")
          .required("Required"),
        password: Yup.string().required("Required"),
      })}
      onSubmit={async (values: UserLoginData) => {
        const data = {
          username: values.username,
          password: values.password,
          scope: "",
        };
        dispatch(clearLoginState());
        makeLoginRequest(data)
          .unwrap()
          .then((result) => {
            localStorage.setItem("isAuthenticated", "true");
            dispatch(clearLoginState());
            dispatch(clearUploadSessionFormState());
            dispatch(setCredentials(result));
            push("/");
          })
          .catch(() => {
            toastError(
              "Unable to sign in with that email address and password.",
            );
          });
      }}
    >
      {({ values, handleSubmit, touched, errors, dirty, isValid }) => (
        <form
          className="mt-2 sm:mx-auto sm:w-full sm:max-w-md"
          data-testid="login-form-component"
          onSubmit={handleSubmit}
        >
          <fieldset className="bg-white py-8 px-4 shadow sm:rounded-lg sm:shadow-lg sm:px-10">
            <div data-testid="email-container">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
                data-testid="email-label"
              >
                Email address
              </label>
              <div className="mt-1">
                <Field
                  id="username"
                  name="username"
                  type="email"
                  autoComplete="email"
                  value={values.username}
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                    touched.username && errors.username
                      ? `border-red-600 focus:ring-red-600 focus:border-red-600`
                      : ""
                  }`}
                  data-testid="email-input"
                />
                {touched.username && errors.username ? (
                  <div
                    className="font-medium text-xs text-red-500 pt-1"
                    data-testid="email-error"
                  >
                    {errors.username}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="pt-2" data-testid="password-container">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
                data-testid="password-label"
              >
                Password
              </label>
              <div className="mt-1">
                <Field
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  value={values.password}
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                    touched.password && errors.password
                      ? `border-red-600 focus:ring-red-600 focus:border-red-600`
                      : ""
                  }`}
                  data-testid="password-input"
                />
                {touched.password && errors.password ? (
                  <div
                    className="font-medium text-xs text-red-500 pt-1"
                    data-testid="password-error"
                  >
                    {errors.password}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex items-center justify-between mt-4">
              <div
                className="flex items-center"
                data-testid="checkbox-container"
              >
                <input
                  id="remember-me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  data-testid="checkbox-input"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                  data-testid="checkbox-label"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm" data-testid="forgot-password-container">
                <Link
                  to="/forgot"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  data-testid="forgot-password-link"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              {isLoading ? (
                <div className="mt-4">
                  <SpinnerButton btnText="Signing In" />
                </div>
              ) : (
                <PrimaryButton
                  className="w-full flex justify-center py-2 px-4 mt-4"
                  data-testid="sign-in-button"
                  disabled={!(dirty && isValid)}
                  type="submit"
                >
                  Sign In
                </PrimaryButton>
              )}
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  );
};
