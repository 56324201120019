import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { toast } from "react-hot-toast";

import { useAppContext } from "../../context";
import {
  useDeactivateApiKeyMutation,
  useDeleteApiKeyMutation,
} from "../../services";
import { toTitleCase } from "../../shared/Functions";
import { ErrorToast, SuccessToast } from "../shared";

type ApiKeyDialogProps = {
  action: string;
  keyId: number;
};

export const ApiKeyDialog: React.FC<ApiKeyDialogProps> = ({
  action,
  keyId,
}: ApiKeyDialogProps): JSX.Element => {
  const { dialogState, setDialogState } = useAppContext();

  // RTK Mutations
  const [deactivateApiKey] = useDeactivateApiKeyMutation();
  const [deleteApiKey] = useDeleteApiKeyMutation();

  const successToast = (message: string) =>
    toast.custom(
      <SuccessToast message={message} classNames="mt-modal w-1/3" />,
    );

  const errorToast = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="mt-modal" />, {
      id: "apiKeyError",
    });

  const deactivateActiveKey = (id: number) => {
    deactivateApiKey(id)
      .unwrap()
      .then((response) => {
        if (response.statusCode === 200) {
          successToast("Successfully Deactivated");
        } else {
          errorToast(
            "There was an error deactivating the key. Please try again.",
          );
        }
      })
      .catch(() => {
        errorToast(
          "There was an error deactivating the key. Please try again.",
        );
      });
    setDialogState({
      shouldOpen: false,
      type: "",
    });
  };

  const deleteDeactivatedKey = (id: number) => {
    deleteApiKey(id)
      .unwrap()
      .then((response) => {
        if (response.statusCode === 200) {
          successToast(" API Key Successfully Deleted");
        } else {
          errorToast("There was an error deleting the key. Please try again.");
        }
      })
      .catch(() => {
        errorToast("There was an error deleting the key. Please try again.");
      });
    setDialogState({
      shouldOpen: false,
      type: "",
    });
  };

  return (
    <Transition.Root show={dialogState.shouldOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() =>
          setDialogState({
            shouldOpen: false,
            type: "",
          })
        }
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                data-testid="dialog-container"
                className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-500"
                      aria-hidden="true"
                      data-testid="exclamation-icon"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                      data-testid="dialog-title"
                    >
                      {toTitleCase(action)} API Key
                    </Dialog.Title>
                    <div className="mt-2">
                      <p
                        className="text-sm text-gray-500"
                        data-testid="dialog-message"
                      >
                        Are you sure you want to {action} your API Key? This
                        action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex justify-end">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() =>
                      setDialogState({
                        shouldOpen: false,
                        type: "",
                      })
                    }
                    data-testid="api-key-dialog-cancel-button"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      if (action === "deactivate") {
                        deactivateActiveKey(keyId);
                      } else if (action === "delete") {
                        deleteDeactivatedKey(keyId);
                      }
                    }}
                    data-testid="action-button"
                  >
                    {toTitleCase(action)}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
