import React from "react";

import {
  disableCustomAnalysis,
  disableEditPlayer,
  joinClassNames,
  showPlayerHandedness,
} from "../../../shared/Functions";

export const TableCellSkeleton = (): JSX.Element => {
  return (
    <div
      className="h-4 w-1/2 bg-gray-200 rounded animate-pulse"
      data-testid="table-cell-skeleton"
    ></div>
  );
};

export const ColumnOneTableCellSkeleton = (): JSX.Element => {
  return (
    <div
      className="h-4 w-1/2 bg-gray-200 rounded animate-pulse"
      data-testid="column-one-skeleton"
    ></div>
  );
};

export const PlayerDetailsHeaderSkeleton = ({
  orgType,
}: {
  orgType: string;
}): JSX.Element => {
  return (
    <div
      className="h-36 w-full px-12 flex bg-gray-50 animate-pulse"
      data-testid="player-details-header-skeleton"
    >
      <div className="h-full w-2/3 flex">
        <div
          className="flex flex-col justify-center space-y-3 w-full mr-24"
          data-testid="player-name-skeleton"
        >
          <div className="h-8 bg-gray-200 rounded w-2/3"></div>
          <div className="h-8 bg-gray-200 rounded w-full"></div>
        </div>
        <div
          className="flex flex-col justify-center space-y-3 w-full"
          data-testid="player-attribute-skeleton"
        >
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-6 bg-gray-200 rounded w-2/3"></div>
        </div>
        <div
          className="flex flex-col justify-center space-y-3 w-full"
          data-testid="player-attribute-skeleton"
        >
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-6 bg-gray-200 rounded w-2/3"></div>
        </div>
        <div
          className="flex flex-col justify-center space-y-3 w-full"
          data-testid="player-attribute-skeleton"
        >
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-6 bg-gray-200 rounded w-2/3"></div>
        </div>
        <div
          className={joinClassNames(
            !showPlayerHandedness(orgType) ? "hidden" : "block",
            "flex flex-col justify-center space-y-3 w-full",
          )}
          data-testid="player-attribute-skeleton"
        >
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-6 bg-gray-200 rounded w-2/3"></div>
        </div>
        <div
          className={joinClassNames(
            !showPlayerHandedness(orgType) ? "hidden" : "block",
            "flex flex-col justify-center space-y-3 w-full",
          )}
          data-testid="player-attribute-skeleton"
        >
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-6 bg-gray-200 rounded w-2/3"></div>
        </div>
      </div>
      <div className="h-full w-1/3 flex justify-end items-center">
        <div
          className={joinClassNames(
            disableEditPlayer(orgType) ? "hidden" : "block",
            "h-10 px-4 pl-4 bg-gray-200 rounded w-14 mr-4",
          )}
          data-testid="edit-button-skeleton"
        ></div>
        <div
          className="h-10 px-4 pl-4 bg-gray-200 rounded w-32"
          data-testid="upload-button-skeleton"
        ></div>
      </div>
    </div>
  );
};

export const SessionDetailsHeaderSkeleton = (): JSX.Element => {
  return (
    <div
      className="flex flex-col justify-start mt-8 max-w-xl px-4 animate-pulse"
      data-testid="session-details-header-skeleton"
    >
      <div className="mx-4 flex-1 space-y-8 py-2">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
        <div className="h-8 bg-gray-200 rounded w-1/3"></div>
        <div className="h-4 bg-gray-200 rounded w-1/3"></div>
      </div>
    </div>
  );
};

export const MobileSkeleton = (): JSX.Element => {
  return (
    <div data-testid="mobile-skeleton-loader-container">
      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-1"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>

      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-2"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>

      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-3"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>

      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-4"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>

      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-5"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>

      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-6"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>

      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-7"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>

      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-8"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>

      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-9"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>

      <div
        className="animate-pulse flex space-x-4 py-4 border-b border-gray-200 h-24 md:px-4"
        data-testid="skeleton-10"
      >
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded w-3/4"></div>
          <div className="h-2 bg-gray-200 rounded w-1/2"></div>
        </div>
        <div className="mx-4 flex-1 space-y-3 py-2">
          <div className="h-2 bg-gray-200 rounded mx-4"></div>
        </div>
      </div>
    </div>
  );
};

export const QuickActionsSkeleton = ({
  orgType,
}: {
  orgType: string;
}): JSX.Element => {
  return (
    <div
      className="w-full flex flex-col mt-4 sm:px-6 lg:px-8 lg:pb-6"
      data-testid="quick-actions-skeleton"
    >
      <div
        className="py-4 h-12 w-56 rounded-lg bg-gray-200 animate-pulse"
        data-testid="quick-actions-header-skeleton"
      ></div>
      <div className="max-w-8xl pt-4 flex flex-wrap">
        <div
          className={joinClassNames(
            disableCustomAnalysis(orgType) ? "hidden" : "block",
            "rounded-lg py-2 px-6 h-32 w-[195px] 2xl:w-56 mr-[14px] mb-[14px] bg-gray-200 animate-pulse",
          )}
          data-testid="quick-action-button-skeleton"
        ></div>
        <div
          className="rounded-lg py-2 px-6 h-32 w-[195px] 2xl:w-56 mr-[14px] mb-[14px] bg-gray-200 animate-pulse"
          data-testid="quick-action-button-skeleton"
        ></div>
        <div
          className="rounded-lg py-2 px-6 h-32 w-[195px] 2xl:w-56 mr-[14px] mb-[14px] bg-gray-200 animate-pulse"
          data-testid="quick-action-button-skeleton"
        ></div>
        <div
          className="rounded-lg py-2 px-6 h-32 w-[195px] 2xl:w-56 mr-[14px] mb-[14px] bg-gray-200 animate-pulse"
          data-testid="quick-action-button-skeleton"
        ></div>
        <div
          className="rounded-lg py-2 px-6 h-32 w-[195px] 2xl:w-56 mr-[14px] mb-[14px] bg-gray-200 animate-pulse"
          data-testid="quick-action-button-skeleton"
        ></div>
        <div
          className="rounded-lg py-2 px-6 h-32 w-[195px] 2xl:w-56 mr-[14px] mb-[14px] bg-gray-200 animate-pulse"
          data-testid="quick-action-button-skeleton"
        ></div>
      </div>
    </div>
  );
};
