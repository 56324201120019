import * as FullStory from "@fullstory/browser";
import { getTime } from "date-fns";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import {
  AnalyzePage,
  CompletedSessions,
  CustomModal,
  DevelopPage,
  ErrorPage,
  FileUploadPopup,
  HomeDashboard,
  PlayerDetails,
  PlayersPage,
  ProcessingSessions,
  SessionDetails,
  // ShotAnalysisPage,
  Stepper,
} from "../";
import {
  DataExportPopup,
  DesktopSidebar,
  FrameRateDialog,
  HelpScoutBeacon,
  NameDialog,
  PageHeader,
  PlayerGroupModal,
  RequestAnalysisForm,
  Sessions,
  StepType,
} from "../../components";
import AppContext from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { dataExportPopupSlice } from "../../redux/slices/dataExportPopupSlice";
import { dataExportSlice } from "../../redux/slices/dataExportSlice";
import { requestedAnalysisFormSlice } from "../../redux/slices/requestedAnalysisFormSlice";
import { requestedAnalysisSlice } from "../../redux/slices/requestedAnalysisSlice";
import { uploadSessionFormSlice } from "../../redux/slices/uploadSessionFormSlice";
import { userSlice } from "../../redux/slices/userSlice";
import { emptyPlayerFormValues } from "../../shared/Constants";
import {
  AbortControllerInfo,
  DialogStateType,
  PlayerFormValues,
  UploadFile,
} from "../../types";
declare global {
  interface Window {
    Beacon: any;
    userGuiding: any;
  }
}

export const App: React.FC = () => {
  // State and AppContext
  const [modalState, setModalState] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [modalStepList, setModalStepList] = useState<Array<StepType>>([]);
  const [modalSingleStep, setModalSingleStep] = useState<JSX.Element>();
  const [modalTitle, setModalTitle] = useState("");
  const [displayBack, setDisplayBack] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [exportPopupState, setExportPopupState] = useState({
    shouldOpen: false,
    title: "",
  });
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [abortControllers, setAbortControllers] = useState<
    AbortControllerInfo[]
  >([]);
  const [dialogState, setDialogState] = useState<DialogStateType>({
    shouldOpen: false,
    type: "",
  });
  const [playerIdToRetrieve, setPlayerIdToRetrieve] = useState("");
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [playerFormValues, setPlayerFormValues] = useState<PlayerFormValues>(
    emptyPlayerFormValues,
  );

  // Redux calls
  const user = useAppSelector((state) => state.user);
  const dataExportFiles = useAppSelector(
    (state) => state.dataExportPopup.files,
  );

  // RTK Slice actions
  const dispatch = useAppDispatch();
  const { logoutUser } = userSlice.actions;
  const { clearUploadSessionFormState } = uploadSessionFormSlice.actions;
  const { clearRequestedAnalysisFormState } =
    requestedAnalysisFormSlice.actions;
  const { clearRequestedAnalysisState } = requestedAnalysisSlice.actions;
  const { clearDataExport } = dataExportSlice.actions;
  const { clearDataExportFiles } = dataExportPopupSlice.actions;

  const tokenExpiryDate = new Date(user.tokenExpiry);
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const lastActivity = localStorage.getItem("lastActivity");
  const lastActiveDate = lastActivity ? new Date(lastActivity) : new Date();

  document.addEventListener("mousemove", () => {
    localStorage.setItem("lastActivity", new Date().toISOString());
  });
  document.addEventListener("click", () => {
    localStorage.setItem("lastActivity", new Date().toISOString());
  });

  let hoursUntilTokenExpires =
    (getTime(tokenExpiryDate) - getTime(lastActiveDate)) / 1000;
  hoursUntilTokenExpires /= 60 * 60;

  useEffect(() => {
    if (Math.round(hoursUntilTokenExpires) <= 1 || user.loginTime === "") {
      localStorage.setItem("isAuthenticated", "false");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursUntilTokenExpires, user]);

  useEffect(() => {
    if (isAuthenticated === "false") {
      dispatch(logoutUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    const currentPopupState: string | null =
      sessionStorage.getItem("popupState");
    const currentPopupTitle: string | null =
      sessionStorage.getItem("popupTitle");
    if (currentPopupState === "true" && currentPopupTitle) {
      setPopupState(true);
      setPopupTitle(currentPopupTitle);
    }
  }, []);

  const closeModal = () => {
    setModalState(false);
    if (modalStepList.length) {
      dispatch(clearUploadSessionFormState());
    }
    const titleNode = document.querySelector("#step-1-title");
    if (modalStepList.length === 3 && titleNode) {
      titleNode.innerHTML = `<div>Select Player</div>`;
    }
    if (modalTitle.includes("Define Export")) {
      dispatch(clearDataExport());
    }
    setCurrentStep(1);
    setModalStepList([]);
    setModalSingleStep(undefined);
    setPlayerFormValues(emptyPlayerFormValues);
  };

  useEffect(() => {
    if (uploadInProgress || exportInProgress) {
      setModalStepList([]);
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [exportInProgress, uploadInProgress]);

  useEffect(() => {
    if (
      !exportInProgress &&
      !exportPopupState.shouldOpen &&
      dataExportFiles.length > 0
    ) {
      dispatch(clearDataExportFiles());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataExportFiles.length, exportInProgress, exportPopupState.shouldOpen]);

  if (isAuthenticated && user.token) {
    // If env-stage or env-prod, identify user with Fullstory and UserGuiding.
    if (
      process.env.REACT_APP_RUN_ENV === "env-stage" ||
      process.env.REACT_APP_RUN_ENV === "env-prod"
    ) {
      FullStory.identify(
        process.env.REACT_APP_RUN_ENV + "-" + String(user.id),
        {
          displayName: user.firstName + " " + user.lastName,
          email: user.email,
        },
      );
      window.userGuiding.identify(user.id, {
        name: user.firstName + " " + user.lastName,
        email: user.email,
      });
    }
  }

  useEffect(() => {
    if (location.pathname !== "/request-analysis") {
      dispatch(clearRequestedAnalysisFormState());
      dispatch(clearRequestedAnalysisState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      {user.token ? (
        <AppContext.Provider
          value={{
            popupTitle,
            setPopupTitle,
            popupState,
            setPopupState,
            exportPopupState,
            setExportPopupState,
            modalState,
            setModalState,
            modalTitle,
            setModalTitle,
            setDisplayBack,
            modalStepList,
            setModalStepList,
            setModalSingleStep,
            currentStep,
            setCurrentStep,
            uploadFiles,
            setUploadFiles,
            abortControllers,
            setAbortControllers,
            dialogState,
            setDialogState,
            playerIdToRetrieve,
            setPlayerIdToRetrieve,
            uploadInProgress,
            setUploadInProgress,
            exportInProgress,
            setExportInProgress,
            loadingDashboard,
            setLoadingDashboard,
            playerFormValues,
            setPlayerFormValues,
          }}
        >
          <div className="h-screen flex overflow-hidden bg-white">
            <Toaster containerStyle={{ zIndex: 999999 }} />
            {dialogState.type === "frameRate" && dialogState.shouldOpen && (
              <FrameRateDialog />
            )}
            <div className="flex flex-col h-full w-full overflow-auto">
              <CustomModal
                shouldShowModal={modalState}
                closeModal={closeModal}
                title={modalTitle}
              >
                <Stepper steps={modalStepList} step={modalSingleStep} />
              </CustomModal>
              <PlayerGroupModal />
              <Router>
                {(dialogState.type === "analysis" ||
                  dialogState.type === "group") &&
                  dialogState.shouldOpen && <NameDialog />}
                <div
                  className="fixed z-10 h-16 bg-white w-full"
                  data-testid="header-parent-container"
                >
                  <div className="w-full h-full">
                    <PageHeader
                      user={user}
                      displayBack={displayBack}
                      data-testid="header-component"
                    />
                  </div>
                </div>
                <div className="flex h-screen w-full mt-16">
                  <DesktopSidebar />
                  <Switch>
                    {!user.token && <Redirect to="/login" />}
                    <Route exact path={"/"} component={HomeDashboard} />
                    <Route exact path={"/sessions"} component={Sessions} />
                    <Route
                      exact
                      path={"/players/:activeTab?"}
                      component={PlayersPage}
                    />
                    <Route
                      exact
                      path={"/processing-sessions"}
                      component={ProcessingSessions}
                    />
                    <Route
                      exact
                      path={"/completed-sessions"}
                      component={CompletedSessions}
                    />
                    <Route
                      exact
                      path={"/player/:playerId"}
                      component={PlayerDetails}
                    />
                    <Route
                      exact
                      path={"/session/:sessionId"}
                      component={SessionDetails}
                    />
                    <Route
                      exact
                      path={"/analyze/:activeTab?"}
                      component={AnalyzePage}
                    />
                    <Route
                      exact
                      path={"/request-analysis"}
                      component={RequestAnalysisForm}
                    />
                    <Route
                      exact
                      path={"/develop/:activeTab?"}
                      component={DevelopPage}
                    />
                    {/* <Route
                      exact
                      path={"/shot-analysis/:activeTab?"}
                      component={ShotAnalysisPage}
                    /> */}
                    <Route exact path={"/error/:code"} component={ErrorPage} />
                    <Redirect to="/error/404" />
                  </Switch>
                </div>
              </Router>
              <FileUploadPopup title={popupTitle} />
              <DataExportPopup
                title={
                  exportPopupState.title
                    ? exportPopupState.title
                    : "Preparing Export"
                }
              />
            </div>
            <HelpScoutBeacon />
          </div>
        </AppContext.Provider>
      ) : (
        <Redirect to={{ pathname: "/login", state: { noToken: true } }} />
      )}
    </>
  );
};
