import {
  ArrowDownTrayIcon,
  CircleStackIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";
import { Row } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  DataExportModal,
  MultipleReportsModal,
  PlayerDetailsHeader,
  Table,
} from "../../components";
import {
  ColumnOneTableCellSkeleton,
  PlayerDetailsHeaderSkeleton,
  TableCellSkeleton,
} from "../../components/shared";
import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { dataExportSlice } from "../../redux/slices/dataExportSlice";
import { playerSlice } from "../../redux/slices/playerSlice";
import {
  useGetPlayerByIdQuery,
  useGetReportsByOrgPlayerIdQuery,
  useGetSessionsByPlayerIdQuery,
} from "../../services";
import {
  columnHelper,
  SharedSessionColumns,
} from "../../shared/columns/SharedSessionColumns";
import { AnalysisCell, sessionListLimit } from "../../shared/Constants";
import {
  displayMovementCount,
  parseCompletedSessions,
  parseInProgressSessions,
  showPlayerHandedness,
  toTitleCase,
} from "../../shared/Functions";
import { Player, Report, Session } from "../../types";

type PlayerRouteParams = {
  playerId: string;
};

export const PlayerDetails: React.FC = (): JSX.Element => {
  const playerRouteParams = useParams<PlayerRouteParams>();
  const orgPlayerId = playerRouteParams.playerId;

  // State and AppContext
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("sessionPageSize") || "10",
  );
  const {
    setDisplayBack,
    setModalState,
    setModalTitle,
    setModalSingleStep,
    playerIdToRetrieve,
  } = useAppContext();
  const [offsetForReports, setOffsetForReports] = useState(0);
  const [offsetForSessions, setOffsetForSessions] = useState(0);
  const [playerReports, setPlayerReports] = useState<Report[]>([]);
  const [endOfReports, setEndOfReports] = useState(false);
  const [playerSessions, setSessionList] = useState<Session[]>([]);
  const [endOfSessions, setEndOfSessions] = useState(false);
  const reportsLimit = 100;
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);

  // Redux calls
  const playerList = useAppSelector((state) => state.players.playerList);
  const orgType = useAppSelector((state) => state.user).org.type;
  const movementTypes = useAppSelector(
    (state) => state.org,
  ).details.movementTypes.map((type) => type.slug);
  const dataExportState = useAppSelector((state) => state.dataExport);

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storePlayers } = playerSlice.actions;
  const { storeDataExport, clearDataExport } = dataExportSlice.actions;

  // RTK Queries
  const { data: playerDetails, isFetching: fetchingPlayerDetails } =
    useGetPlayerByIdQuery(orgPlayerId, { skip: selectedPlayer !== null });
  const {
    data: sessions,
    isFetching: fetchingSessions,
    originalArgs: sessionsOriginalArgs,
  } = useGetSessionsByPlayerIdQuery({
    orgPlayerId,
    offset: offsetForSessions,
    limit: sessionListLimit,
  });
  const {
    data: reports,
    isFetching: fetchingReports,
    originalArgs: reportsOriginalArgs,
  } = useGetReportsByOrgPlayerIdQuery({
    orgPlayerId,
    offset: offsetForReports,
    limit: reportsLimit,
    movementTypes,
  });
  const { data: editedPlayer } = useGetPlayerByIdQuery(playerIdToRetrieve, {
    skip: !playerIdToRetrieve,
  });

  useEffect(() => {
    if (playerDetails) {
      setSelectedPlayer(playerDetails);
    }
  }, [playerDetails]);

  const exportDataDropdownOptions = [
    { name: "Export CSV", value: "csv", Icon: TableCellsIcon },
    { name: "Export Parquet", value: "parquet", Icon: CircleStackIcon },
  ];

  useEffect(() => {
    if (sessions && sessionsOriginalArgs) {
      if (!endOfSessions && sessions.length === sessionListLimit) {
        setOffsetForSessions(sessionsOriginalArgs.offset + sessionListLimit);
        setSessionList(playerSessions.concat(sessions));
      } else if (!endOfSessions && sessions.length < sessionListLimit) {
        setSessionList(playerSessions.concat(sessions));
        setEndOfSessions(true);
        setOffsetForSessions(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessions]);

  useEffect(() => {
    if (reports && reportsOriginalArgs) {
      if (!endOfReports && reports.length === reportsLimit) {
        setOffsetForReports(reportsOriginalArgs.offset + reportsLimit);
        setPlayerReports(playerReports.concat(reports));
      } else if (!endOfReports && reports.length < reportsLimit) {
        setPlayerReports(playerReports.concat(reports));
        setEndOfReports(true);
        setOffsetForReports(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  useEffect(() => {
    if (editedPlayer) {
      setSelectedPlayer(editedPlayer);
      const updatedPlayerList = playerList
        .filter((player) => player.orgPlayerId !== playerIdToRetrieve)
        .concat(editedPlayer);
      dispatch(storePlayers(updatedPlayerList));

      window.history.pushState({}, "", `/player/${editedPlayer.orgPlayerId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedPlayer]);

  const memoizedSharedSessionColumns = useMemo(SharedSessionColumns, []);

  const playerDetailColumns = useMemo(
    () => [
      columnHelper.accessor("checkbox", {
        header: "",
        enableSorting: false,
        cell: function checkboxCell(element: any) {
          const session = element.row.original;
          if (session.status === "in-progress") {
            return null;
          } else {
            return (
              <input
                type="checkbox"
                className="border-gray-300 rounded-[4px] mr-4 checked:bg-[#FB6A07] checked:focus:bg-[#FB6A07] checked:hover:bg-[#FB6A07] focus:ring-[#FB6A07] focus:ring-opacity-50 focus:ring-2"
                data-testid="session-checkbox"
                defaultChecked={dataExportState.sessionList.some(
                  (s) => s.id === session.id,
                )}
                onChange={() => handleCheckboxChange(session)}
              />
            );
          }
        },
      }),
      ...memoizedSharedSessionColumns,
      columnHelper.accessor("hand", {
        header: "Hand",
        sortingFn: (rowA: Row<any>, rowB: Row<any>) => {
          const handsRowA = rowA.original.hand.split(", ");
          const handsRowB = rowB.original.hand.split(", ");
          if (handsRowA[0] > handsRowB[0]) {
            return 1;
          }
          if (handsRowB[0] > handsRowA[0]) {
            return -1;
          }
          return 0;
        },
        sortDescFirst: false,
        cell: function handCell(element: any) {
          return <p data-testid="hand-cell">{element.getValue()}</p>;
        },
      }),
      columnHelper.accessor("analysis", {
        header: "Analysis",
        enableSorting: false,
        cell: function analysisCell(element: any) {
          return (
            <>
              <AnalysisCell
                element={element}
                fetchingReports={fetchingReports}
                session={element.row.original}
                openReportsModal={viewPlayerReports}
              />
            </>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dataExportState.sessionList,
      fetchingReports,
      memoizedSharedSessionColumns,
    ],
  );

  const columnsWithoutHand = useMemo(
    () => playerDetailColumns.filter((column) => column.accessorKey !== "hand"),
    [playerDetailColumns],
  );

  const playerCompletedSessions: Session[] = parseCompletedSessions(
    playerSessions?.filter((session) => {
      return session.percentComplete > 99;
    }) || [],
    playerReports,
  );
  const playerProcessingSessions: Session[] = parseInProgressSessions(
    playerSessions?.filter((session) => {
      return session.percentComplete <= 99;
    }) || [],
    playerReports,
  );
  const finalPlayerSessions: Session[] = [
    ...playerCompletedSessions,
    ...playerProcessingSessions,
  ].sort((a: Session, b: Session): number => {
    return a.sessionDate > b.sessionDate ? -1 : 1;
  });

  useEffect(() => {
    if (sessions && sessions.length > playerSessions.length) {
      setSessionList(sessions);
    }
  }, [sessions, playerSessions]);

  const tableData = useMemo(
    () => (fetchingSessions ? Array(10).fill({}) : finalPlayerSessions),
    [fetchingSessions, finalPlayerSessions],
  );

  const tableColumns = useMemo(
    () =>
      fetchingSessions
        ? (!showPlayerHandedness(orgType)
            ? columnsWithoutHand
            : playerDetailColumns
          ).map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : !showPlayerHandedness(orgType)
          ? columnsWithoutHand
          : playerDetailColumns,
    [columnsWithoutHand, fetchingSessions, orgType, playerDetailColumns],
  );

  const viewPlayerReports = (session: any) => {
    const numberOfMovements = displayMovementCount(
      session.numberOfMovements,
      true,
    );
    setModalTitle(
      `${format(parseISO(session.sessionDate), "M/d/yyyy")} - ${
        session.sessionType.name
      } - ${numberOfMovements}`,
    );
    setModalState(true);
    setModalSingleStep(<MultipleReportsModal reports={session.reports} />);
  };

  const openExportDataModal = (fileType: string) => {
    dispatch(
      storeDataExport({
        ...dataExportState,
        playerName: selectedPlayer?.firstName + " " + selectedPlayer?.lastName,
        orgPlayerId: orgPlayerId,
        dataFormat: fileType,
      }),
    );

    setModalTitle(
      `Define Export - ${
        fileType === "csv" ? fileType.toUpperCase() : toTitleCase(fileType)
      }`,
    );
    setModalState(true);
    setModalSingleStep(<DataExportModal />);
  };

  const handleCheckboxChange = (session: Session) => {
    const checkedSession = {
      id: session.id,
      date: format(new Date(session.sessionDate), "M/d/yyyy"),
    };

    if (dataExportState.sessionList.some((s) => s.id === session.id)) {
      const updatedSelectedSessions = dataExportState.sessionList.filter(
        (s) => s.id !== session.id,
      );
      dispatch(
        storeDataExport({
          ...dataExportState,
          sessionList: updatedSelectedSessions,
        }),
      );
    } else {
      dispatch(
        storeDataExport({
          ...dataExportState,
          sessionList: [...dataExportState.sessionList, checkedSession],
        }),
      );
    }
  };

  useEffect(() => {
    setDisplayBack && setDisplayBack(true);
  }, [setDisplayBack]);

  useEffect(() => {
    dispatch(clearDataExport());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="relative pb-20 z-0 overflow-y-auto w-full">
      <div className="" data-testid="header-container">
        {fetchingPlayerDetails ? (
          <PlayerDetailsHeaderSkeleton orgType={orgType} />
        ) : !fetchingPlayerDetails && selectedPlayer ? (
          <PlayerDetailsHeader player={selectedPlayer} />
        ) : (
          <div className="h-36 w-full px-12 flex bg-gray-50 items-center">
            <p className="text-gray-700 tracking-wider"> Player Not Found</p>
          </div>
        )}
      </div>
      <div className="flex justify-start mt-8">
        <h2
          className="max-w-7xl mt-4 px-4 text-3xl leading-6 font-medium text-gray-900 sm:px-6 lg:px-12 lg:pb-6"
          data-testid="player-sessions-header"
        >
          Sessions
        </h2>
      </div>
      <div className="px-4 block min-w-max" data-testid="player-sessions-table">
        <Table
          columns={tableColumns}
          data={tableData}
          selectedPageSize={selectedPageSize}
          setSelectedPageSize={setSelectedPageSize}
          displayFooter={true}
          fetching={fetchingSessions}
          customRowClassName="player-details-row"
          component="playerDetails"
          buttons={[
            {
              type: "dropdown",
              action: openExportDataModal,
              text: "Export Data",
              dataTestid: "export-data-dropdown-button",
              Icon: ArrowDownTrayIcon,
              dropdownList: exportDataDropdownOptions,
            },
          ]}
          disableButton={dataExportState.sessionList.length === 0}
          filters={["sessionType", "sessionDate"]}
        />
      </div>
    </main>
  );
};
