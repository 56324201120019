import { format, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import Select from "react-select";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { requestedAnalysisFormSlice } from "../../redux/slices/requestedAnalysisFormSlice";
import {
  customSelectStyles,
  dateRangeOptions,
  today,
} from "../../shared/Constants";
import { SegmentDetails } from "../../types";
import {
  CustomDropdownWithCalendar,
  DateRangePicker,
  MultiDatePicker,
} from "../shared";

type SegmentDateRangeProps = {
  segmentType: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  segmentKey: string;
  currentSegmentState: SegmentDetails;
};

export const SegmentDateRange: React.FC<SegmentDateRangeProps> = ({
  segmentType,
  setFieldValue,
  setFieldTouched,
  segmentKey,
  currentSegmentState,
}: SegmentDateRangeProps): JSX.Element => {
  // State
  const [startDate, setStartDate] = useState<null | Date>(null);
  const [endDate, setEndDate] = useState<null | Date>(null);
  const [selectedDates, setSelectedDates] = useState<any[]>([]);

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeRequestedAnalysisFormState } =
    requestedAnalysisFormSlice.actions;

  // Redux calls
  const requestedAnalysisFormState = useAppSelector(
    (state) => state.requestedAnalysisForm,
  );
  const requestedAnalysisState = useAppSelector(
    (state) => state.requestedAnalysis,
  );

  useEffect(() => {
    if (
      segmentType === "primary" &&
      requestedAnalysisState.primarySegmentData.criteria.sessionDate
    ) {
      setStartDate(
        requestedAnalysisState.primarySegmentData.criteria.sessionDate[0]
          ? new Date(
              `${requestedAnalysisState.primarySegmentData.criteria.sessionDate[0]} 00:00:00`,
            )
          : null,
      );
      setEndDate(
        requestedAnalysisState.primarySegmentData.criteria.sessionDate[1]
          ? new Date(
              `${requestedAnalysisState.primarySegmentData.criteria.sessionDate[1]} 00:00:00`,
            )
          : null,
      );
    } else if (
      segmentType === "population" &&
      requestedAnalysisState.populationSegmentData.criteria.sessionDate
    ) {
      setStartDate(
        requestedAnalysisState.populationSegmentData.criteria.sessionDate[0]
          ? new Date(
              `${requestedAnalysisState.populationSegmentData.criteria.sessionDate[0]} 00:00:00`,
            )
          : null,
      );
      setEndDate(
        requestedAnalysisState.populationSegmentData.criteria.sessionDate[1]
          ? new Date(
              `${requestedAnalysisState.populationSegmentData.criteria.sessionDate[1]} 00:00:00`,
            )
          : null,
      );
    } else if (
      segmentType === "comparison" &&
      requestedAnalysisState.comparisonSegmentData.criteria.sessionDate
    ) {
      setStartDate(
        requestedAnalysisState.comparisonSegmentData.criteria.sessionDate[0]
          ? new Date(
              `${requestedAnalysisState.comparisonSegmentData.criteria.sessionDate[0]} 00:00:00`,
            )
          : null,
      );
      setEndDate(
        requestedAnalysisState.comparisonSegmentData.criteria.sessionDate[1]
          ? new Date(
              `${requestedAnalysisState.comparisonSegmentData.criteria.sessionDate[1]} 00:00:00`,
            )
          : null,
      );
    }
  }, [requestedAnalysisState, segmentType]);

  useEffect(() => {
    let dates: Date[] = [];
    if (
      segmentType === "primary" &&
      requestedAnalysisState.primarySegmentData.criteria.sessionDateIn
    ) {
      dates =
        requestedAnalysisState.primarySegmentData.criteria.sessionDateIn.map(
          (date: string) => new Date(`${date} 00:00:00`),
        );
    } else if (
      segmentType === "population" &&
      requestedAnalysisState.populationSegmentData.criteria.sessionDateIn
    ) {
      dates =
        requestedAnalysisState.populationSegmentData.criteria.sessionDateIn.map(
          (date: string) => new Date(`${date} 00:00:00`),
        );
    } else if (
      segmentType === "comparison" &&
      requestedAnalysisState.comparisonSegmentData.criteria.sessionDateIn
    ) {
      dates =
        requestedAnalysisState.comparisonSegmentData.criteria.sessionDateIn.map(
          (date: string) => new Date(`${date} 00:00:00`),
        );
    }
    setSelectedDates(dates);
  }, [requestedAnalysisState, segmentType]);

  const determineDateRange = (value: string) => {
    setStartDate(null);
    setEndDate(null);
    const currentDay = format(new Date(today), "yyyy-MM-dd");

    if (value === "Today") {
      return [currentDay];
    } else if (value === "Yesterday") {
      return [format(subDays(new Date(), 1), "yyyy-MM-dd")];
    } else if (value === "Last 7 Days") {
      return [format(subDays(new Date(), 7), "yyyy-MM-dd"), currentDay];
    } else if (value === "Last 14 Days") {
      return [format(subDays(new Date(), 14), "yyyy-MM-dd"), currentDay];
    } else if (value === "Last 30 Days") {
      return [format(subDays(new Date(), 30), "yyyy-MM-dd"), currentDay];
    } else if (value === "Last 60 Days") {
      return [format(subDays(new Date(), 60), "yyyy-MM-dd"), currentDay];
    } else if (value === "Last Year") {
      return [format(subDays(new Date(), 365), "yyyy-MM-dd"), currentDay];
    }
  };

  const handleDateRangeBlur = () => {
    setFieldTouched(`${segmentType}Segment.dateRange`, true);
  };

  const handleMultipleDatesBlur = () => {
    setFieldTouched(`${segmentType}Segment.dateRange`, true);
  };

  useEffect(() => {
    if (startDate && endDate) {
      const customDateRange = [
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd"),
      ];
      setFieldValue(`${segmentType}Segment.dateRange`, customDateRange);
      dispatch(
        storeRequestedAnalysisFormState({
          ...requestedAnalysisFormState,
          [segmentKey]: {
            ...currentSegmentState,
            dateRange: customDateRange,
            individualDates: [],
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  useEffect(() => {
    if (selectedDates.length > 0) {
      const formattedDates = selectedDates.map((date) =>
        format(date, "yyyy-MM-dd"),
      );

      setFieldValue(`${segmentType}Segment.dateRange`, formattedDates);
      dispatch(
        storeRequestedAnalysisFormState({
          ...requestedAnalysisFormState,
          [segmentKey]: {
            ...currentSegmentState,
            individualDates: formattedDates,
            dateRange: [],
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates]);

  return (
    <div data-testid="date-range-container">
      <label
        className="text-gray-900"
        data-testid={`${segmentType}-date-range-label`}
      >
        Date Range
      </label>

      <div className="flex">
        <div
          className="w-60 mt-4 mr-4"
          data-testid={`${segmentType}-date-range-dropdown-container`}
        >
          <Select
            name={`${segmentType}-date-range-dropdown`}
            aria-labelledby="date-range-dropdown-aria-label"
            inputId={`${segmentType}-date-range-dropdown`}
            options={dateRangeOptions}
            value={
              currentSegmentState.dateCriteria.value !== ""
                ? {
                    label: currentSegmentState.dateCriteria.value,
                    value: currentSegmentState.dateCriteria.label,
                  }
                : null
            }
            isSearchable={false}
            styles={customSelectStyles}
            components={{
              DropdownIndicator: CustomDropdownWithCalendar,
              IndicatorSeparator: () => null,
            }}
            placeholder="Select Date Range"
            onChange={(dateCriteria) => {
              if (dateCriteria) {
                const dateRange = determineDateRange(dateCriteria.label);
                setFieldValue(
                  `${segmentType}Segment.dateCriteria`,
                  dateCriteria,
                );
                setFieldValue(`${segmentType}Segment.dateRange`, dateRange);
                dispatch(
                  storeRequestedAnalysisFormState({
                    ...requestedAnalysisFormState,
                    [segmentKey]: {
                      ...currentSegmentState,
                      dateCriteria: dateCriteria,
                      dateRange,
                      individualDates: [],
                    },
                  }),
                );
              }
            }}
          />
        </div>

        {currentSegmentState.dateCriteria.label === "Custom Range" && (
          <div className="w-60 mt-4">
            <DateRangePicker
              placeholderText={"Select Range"}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              segmentType={segmentType}
              onBlur={handleDateRangeBlur}
            />
          </div>
        )}

        {currentSegmentState.dateCriteria.label === "Individual Dates" && (
          <div className="w-60 mt-4">
            <MultiDatePicker
              selectedDates={selectedDates}
              setSelectedDates={setSelectedDates}
              segmentType={segmentType}
              onBlur={handleMultipleDatesBlur}
            />
          </div>
        )}
      </div>
    </div>
  );
};
