import { DocumentChartBarIcon } from "@heroicons/react/24/outline";
import React, { useMemo } from "react";

import { useAppContext } from "../../context";
import { columnHelper } from "../../shared/columns/SharedSessionColumns";
import { determineHandsForReports } from "../../shared/Functions";
import { ReportType } from "../../types";
import { AnalysisButton, BlankButton } from "../shared";
import { Table } from "../Table/Table";

type MultipleReportsModalProps = {
  reports: ReportType[];
};

export const MultipleReportsModal: React.FC<MultipleReportsModalProps> = ({
  reports,
}: MultipleReportsModalProps): JSX.Element => {
  const { setModalState } = useAppContext();

  const multipleReportsColumns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: function nameCell(element: any) {
          return (
            <div data-testid="name-cell">
              <p data-testid="name-text" className="font-bold text-gray-900">
                {element.row.original.name}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("version", {
        header: "Version",
        enableSorting: false,
        cell: function versionCell(element: any) {
          return (
            <div data-testid="version-cell">
              <p data-testid="version-text" className="text-gray-900">
                {element.row.original.version}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("hand", {
        header: "Hand",
        cell: function handCell(element: any) {
          return (
            <div data-testid="hand-cell">
              <p data-testid="hand-text" className="text-gray-900">
                {element.row.original.hand}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("analysis", {
        header: "View Analysis",
        enableSorting: false,
        cell: function analysisCell(element: any) {
          return (
            <div className="whitespace-normal" data-testid="analysis-cell">
              <a
                href={element.row.original.reportUrl}
                target="_blank"
                rel="noreferrer"
                data-testid="analysis-link"
              >
                <AnalysisButton data-testid="view-analysis-button">
                  <DocumentChartBarIcon className="w-5" />
                </AnalysisButton>
              </a>
            </div>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const multipleReportsData = reports.map((report: ReportType) => {
    const hand = determineHandsForReports([report]);
    let reportName;
    let version;

    if (report.movementType === "baseball-hitting") {
      reportName = "Hitting Report";
    } else if (report.movementType === "baseball-pitching") {
      reportName = "Pitching Report";
    } else {
      reportName = "Report";
    }

    if (reportName === "Hitting Report") {
      if (report.reportVersion === 1) {
        version = "v1";
      } else if (report.reportVersion === 2) {
        version = "v2";
      } else {
        version = "";
      }
    } else {
      version = "";
    }

    return {
      ...report,
      hand,
      name: reportName,
      version,
    };
  });

  return (
    <div>
      <div
        className="block min-w-max mt-10 mb-40 mx-20"
        data-testid="multiple-reports-table-container"
      >
        <Table
          columns={multipleReportsColumns}
          data={multipleReportsData}
          data-testid="multiple-reports-table"
          selectedPageSize={reports.length.toString()}
          displayFooter={false}
          fetching={false}
          hideSearch={true}
        />
      </div>
      <footer
        data-testid="form-buttons-container"
        className="flex justify-end mb-5 pt-5 pr-5 w-full border-t border-gray-200"
      >
        <BlankButton
          data-testid="multiple-reports-modal-cancel-button"
          type="button"
          onClick={() => {
            setModalState(false);
          }}
        >
          Cancel
        </BlankButton>
      </footer>
    </div>
  );
};
