import { Column } from "@tanstack/react-table";
import React, { useState } from "react";
import Select from "react-select";

import { customSelectStyles } from "../../shared/Constants";
import { CustomDropdownWithChevron } from "../shared";

type FilterProps = {
  column: Column<Record<string, unknown>, unknown> | undefined;
};

type FilterOption = {
  value: string;
  label: string;
};

export const RequestedAnalysesFilter: React.FC<FilterProps> = ({
  column,
}: FilterProps): JSX.Element => {
  const [filterValue, setFilterValue] = useState<FilterOption>({
    value: "",
    label: "All Analyses",
  });

  const filterOptions = [
    { value: "", label: "All Analyses" },
    { value: "requested", label: "Requested" },
    { value: "complete", label: "Complete" },
  ];

  return (
    <div
      className="flex flex-col justify-center static w-40 h-full ml-8"
      data-testid="requested-analyses-filter-container"
    >
      <Select
        name="requested-analyses-filter"
        aria-labelledby="requested-analyses-filter-aria-label"
        inputId="requested-analyses-filter"
        options={filterOptions}
        value={filterValue}
        isSearchable={false}
        styles={customSelectStyles}
        components={{
          DropdownIndicator: CustomDropdownWithChevron,
          IndicatorSeparator: () => null,
        }}
        data-testid="requested-analyses-filter-select"
        onChange={(statusOption) => {
          if (column && statusOption) {
            setFilterValue(statusOption);
            column.setFilterValue(statusOption.value);
          }
        }}
      />
    </div>
  );
};
