import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { awsCredentialsSlice } from "../../redux/slices/awsCredentialsSlice";
import { useGetAwsCredentialsQuery } from "../../services/awsCredentials";
import { ErrorToast, PrimaryButton, SpinnerButton } from "../shared";

type NotebookProps = {
  page: string;
  content: string;
  generateCredentials: boolean;
  setGenerateCredentials: React.Dispatch<React.SetStateAction<boolean>>;
  viewCredentials: boolean;
  setViewCredentials: React.Dispatch<React.SetStateAction<boolean>>;
  notebookLink: string;
};

export const Notebook: React.FC<NotebookProps> = ({
  page,
  content,
  generateCredentials,
  setGenerateCredentials,
  viewCredentials,
  setViewCredentials,
  notebookLink,
}: NotebookProps): JSX.Element => {
  // Redux Actions
  const { setAwsCredentials } = awsCredentialsSlice.actions;
  const dispatch = useAppDispatch();

  // Redux State
  const credentialsExpirationDate = useAppSelector(
    (state) => state.awsCredentials.expiration,
  );

  // RTK Query
  const {
    data: awsCredentialsData,
    isFetching: fetchingCredentials,
    error,
  } = useGetAwsCredentialsQuery(undefined, {
    skip: !generateCredentials,
  });

  const toastError = (message: string) =>
    toast.custom(
      <ErrorToast message={message} classNames={"mt-modal max-w-2xl"} />,
      {
        id: "errorGeneratingCredentials",
        duration: 4000,
      },
    );

  const handleGenerateCredentials = () => {
    if (credentialsExpirationDate !== "") {
      const expirationDate = new Date(credentialsExpirationDate);
      const currentDate = new Date();
      if (currentDate > expirationDate) {
        setGenerateCredentials(true);
      } else {
        setViewCredentials(true);
      }
    } else {
      setGenerateCredentials(true);
    }
  };

  useEffect(() => {
    setViewCredentials(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (awsCredentialsData) {
      dispatch(setAwsCredentials(awsCredentialsData));
      setViewCredentials(true);
      setGenerateCredentials(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateCredentials, awsCredentialsData]);

  useEffect(() => {
    if (error) {
      if ("status" in error && error.status === 403) {
        toastError(
          "Your organization does not have access to generate credentials. Please contact support@rebootmotion.com if you believe this is in error.",
        );
        setGenerateCredentials(false);
      } else {
        toastError(
          "An error occurred while generating credentials. Please try again.",
        );
        setGenerateCredentials(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <main
      className="relative z-0 overflow-y-auto w-full mt-8 px-8 flex justify-center"
      data-testid={`${page}-notebook-container`}
    >
      <div className="flex flex-col items-center">
        <h2
          className="font-medium text-2xl text-gray-900 text-center max-w-5xl py-12"
          data-testid={`${page}-text-content`}
        >
          {content}
        </h2>
        <div className="mt-8 flex">
          {fetchingCredentials ? (
            <div className="w-48">
              <SpinnerButton btnText={"Processing"} />
            </div>
          ) : (
            <PrimaryButton
              data-testid={`${page}-generate-credentials-button`}
              onClick={handleGenerateCredentials}
              disabled={viewCredentials}
            >
              Generate Credentials
            </PrimaryButton>
          )}
          <a
            href={notebookLink}
            target="_blank"
            rel="noreferrer"
            data-testid={`${page}-launch-notebook-link`}
          >
            <PrimaryButton className="flex justify-center items-center ml-8">
              Launch Notebook
              <ArrowTopRightOnSquareIcon className="ml-2 h-5 w-5" />
            </PrimaryButton>
          </a>
        </div>
      </div>
    </main>
  );
};
