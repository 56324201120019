import { Field, Formik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { useSetNewPasswordMutation } from "../../services/user";
import { ErrorToast, PrimaryButton, SuccessToast } from "../shared";

export type ResetPasswordFormProps = {
  email: string;
  token: string;
};

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  email,
  token,
}: ResetPasswordFormProps) => {
  const { push } = useHistory();

  const [setNewPassword, isSuccess] = useSetNewPasswordMutation();

  const success = (message: string) =>
    toast.custom(<SuccessToast message={message} classNames="mt-modal" />, {
      duration: 4000,
    });

  const error = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames="mt-modal" />, {
      id: "resetPasswordError",
      duration: 4000,
    });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: email,
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string(),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\-\_])(?=.{8,})/,
            "Password must contain at least 8 characters, one letter, one number, and one special character",
          )
          .required(""),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required(""),
      })}
      onSubmit={(values) => {
        const newPasswordData = {
          username: email,
          new_password: values.password,
          token: token,
        };
        setNewPassword(newPasswordData)
          .unwrap()
          .then(() => {
            if (isSuccess) {
              success("Password successfully changed.");
              push("/login");
            }
          })
          .catch(() => {
            error("Unable to reset password. Please try again.");
          });
      }}
    >
      {({
        values,
        handleSubmit,
        touched,
        errors,
        setFieldTouched,
        dirty,
        isValid,
      }) => (
        <form
          className="mt-2 sm:mx-auto sm:w-full sm:max-w-md"
          data-testid="reset-form-component"
          onSubmit={handleSubmit}
        >
          <fieldset className="bg-white py-8 px-4 shadow sm:rounded-lg sm:shadow-lg sm:px-10">
            <div data-testid="email-container">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
                data-testid="email-label"
              >
                Email address
              </label>
              <div className="mt-1">
                <Field
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm cursor-not-allowed text-gray-400 sm:text-sm"
                  data-testid="email-input"
                  disabled
                />
              </div>
            </div>

            <div data-testid="password-container">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 pt-4"
                data-testid="password-label"
              >
                New password <span className="text-red-500 text-xs">*</span>
              </label>
              <div className="mt-1">
                <Field
                  type="password"
                  id="password"
                  name="password"
                  value={values.password}
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                    touched.password && errors.password
                      ? `border-red-600 focus:ring-red-600 focus:border-red-600`
                      : ""
                  }`}
                  data-testid="password-input"
                  onInput={() => setFieldTouched("password", true, true)}
                />
                {touched.password && errors.password ? (
                  <div
                    className="font-medium text-xs text-red-500 pt-1"
                    data-testid="password-error"
                  >
                    {errors.password}
                  </div>
                ) : null}
                <p
                  className={`text-gray-400 text-xs pt-1 ${
                    errors.password ==
                    "Password must contain at least 8 characters, one letter, one number, and one special character"
                      ? "hidden"
                      : ""
                  }`}
                  data-testid="password-validation-text"
                >
                  Password must contain at least 8 characters, one letter, one
                  number, and one special character
                </p>
              </div>
            </div>

            <div data-testid="password-confirmation-container">
              <label
                htmlFor="passwordConfirmation"
                className="block text-sm font-medium text-gray-700 pt-4"
                data-testid="password-confirmation-label"
              >
                Confirm password <span className="text-red-500 text-xs">*</span>
              </label>
              <div className="mt-1">
                <Field
                  type="password"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  value={values.passwordConfirmation}
                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm capitalize ${
                    touched.passwordConfirmation && errors.passwordConfirmation
                      ? `border-red-600 focus:ring-red-600 focus:border-red-600`
                      : ""
                  }`}
                  data-testid="password-confirmation-input"
                  onInput={() =>
                    setFieldTouched("passwordConfirmation", true, true)
                  }
                />
                {touched.passwordConfirmation && errors.passwordConfirmation ? (
                  <div
                    className="font-medium text-xs text-red-500 pt-1"
                    data-testid="password-confirmation-error"
                  >
                    {errors.passwordConfirmation}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="pt-2">
              <PrimaryButton
                className="w-full flex justify-center py-2 px-4 mt-4"
                data-testid="save-button"
                disabled={!(dirty && isValid)}
                type="submit"
              >
                Save
              </PrimaryButton>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  );
};
