import { add, addDays, format, parse } from "date-fns";
import { parseISO } from "date-fns/esm";
import { Formik } from "formik";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Select, { OptionsType } from "react-select";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { uploadSessionFormSlice } from "../../../redux/slices/uploadSessionFormSlice";
import {
  CustomCalendarIcon,
  customSelectStyles,
} from "../../../shared/Constants";
import { toTitleCase } from "../../../shared/Functions";
import { MovementTag, Org, SelectField } from "../../../types";
import { CustomDropdownWithChevron } from "../components/CustomSelectComponents";

export type SessionUploadFormProps = {
  orgData: Org;
};

export const SessionUploadForm: React.FC<SessionUploadFormProps> = ({
  orgData,
}) => {
  const dispatch = useAppDispatch();
  const [, setIsMenuOpen] = useState(false);

  const { storeUploadSessionFormState } = uploadSessionFormSlice.actions;
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const movementTagsObjects: Record<string, MovementTag[]> = {};

  const sessionTypeOptions = orgData.details.sessionTypes
    ? orgData.details.sessionTypes.map((session) => {
        return { value: `${session.id}`, label: `${session.name}` };
      })
    : [];

  const sessionVenueOptions =
    !orgData.details.sessionVenues || orgData.details.sessionVenues.length === 0
      ? [{ value: "0", label: "Default Venue" }]
      : orgData.details.sessionVenues.map((venue) => {
          return { value: `${venue.id}`, label: `${venue.slug}` };
        });

  const movementTypeOptions = orgData.details.movementTypes.map((movement) => {
    movementTagsObjects[movement.id] = movement.tags;
    return { value: `${movement.id}`, label: `${toTitleCase(movement.slug)}` };
  });

  const movementTagsOptions =
    uploadSessionFormValues.movementType.value === ""
      ? [{ value: "", label: "" }]
      : movementTagsObjects[
          parseInt(uploadSessionFormValues.movementType.value)
        ].map((movementTag) => {
          return {
            value: `${movementTag.id}`,
            label: toTitleCase(movementTag.slug),
          };
        });

  function handleMultiSelect(
    tags: OptionsType<{ value: string; label: string }>,
  ) {
    dispatch(
      storeUploadSessionFormState({
        ...uploadSessionFormValues,
        movementTags: tags.map((tag) => {
          return tag;
        }),
      }),
    );
  }

  return (
    <Formik
      initialValues={uploadSessionFormValues}
      validationSchema={Yup.object({
        sessionDate: Yup.date().max(add(new Date(), { days: 1 })),
      })}
      onSubmit={() => {
        false;
      }}
    >
      {({ handleSubmit }) => (
        <form
          className="flex flex-col h-full w-full items-center overflow-visible pb-8 sm:pb-0"
          onSubmit={handleSubmit}
        >
          <fieldset className="w-11/12 sm:pt-10 flex flex-col sm:flex-row justify-between">
            <div
              className="flex flex-col sm:w-5/12 space-y-8"
              data-testid="session-form-fields-container"
            >
              <div data-testid="session-date-container">
                <div className="flex justify-between">
                  <label
                    id="session-date-label"
                    htmlFor="session-date-date-picker"
                    className="block text-sm font-medium text-gray-500 pb-1"
                  >
                    Session Date
                  </label>
                  <span className="text-sm text-gray-300">Required</span>
                </div>
                <div
                  className="flex items-center justify-between w-full h-9 border border-gray-200 rounded-md focus-within:border-indigo-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  data-testid="date-picker-container"
                >
                  <DatePicker
                    id="session-date-picker"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText={"Select Session Date"}
                    selected={
                      typeof uploadSessionFormValues.sessionDate === "string" &&
                      uploadSessionFormValues.sessionDate !== ""
                        ? parseISO(uploadSessionFormValues.sessionDate)
                        : uploadSessionFormValues.sessionDate === ""
                          ? new Date()
                          : typeof uploadSessionFormValues.sessionDate ===
                              "string"
                            ? parse(
                                uploadSessionFormValues.sessionDate,
                                "MMMM d, yyyy",
                                new Date(),
                              )
                            : uploadSessionFormValues.sessionDate
                    }
                    dateFormat="MMMM d, yyyy"
                    name="sessionDate"
                    todayButton="Today"
                    maxDate={addDays(new Date(), 0)}
                    className="font-medium text-base w-full text-gray-900 h-8 py-0.5 rounded-md border-none sm:text-sm cursor-default"
                    onChange={(date) => {
                      if (date instanceof Date && date.getTime()) {
                        dispatch(
                          storeUploadSessionFormState({
                            ...uploadSessionFormValues,
                            sessionDate: format(
                              parseISO(date.toISOString()),
                              "yyyy-MM-dd",
                            ),
                          }),
                        );
                      }
                    }}
                  />
                  <CustomCalendarIcon />
                </div>
              </div>
              <div className="" data-testid="session-type-container">
                <div className="flex justify-between">
                  <label
                    id="session-type-label"
                    htmlFor="session-type-select"
                    className="block text-sm font-medium text-gray-500 pb-1"
                  >
                    Session Type
                  </label>
                  <span className="text-sm text-gray-300">Required</span>
                </div>
                <Select
                  classNamePrefix="session-type-select"
                  inputId="session-type-select"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={sessionTypeOptions}
                  value={{
                    value: uploadSessionFormValues.sessionType.value,
                    label: uploadSessionFormValues.sessionType.label,
                  }}
                  onChange={(sessionType) => {
                    if (sessionType) {
                      dispatch(
                        storeUploadSessionFormState({
                          ...uploadSessionFormValues,
                          sessionType: sessionType,
                        }),
                      );
                    }
                  }}
                  isSearchable={false}
                  styles={customSelectStyles}
                  components={{
                    DropdownIndicator: CustomDropdownWithChevron,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div className="" data-testid="session-venue-container">
                <div className="flex justify-between">
                  <label
                    id="session-venue-label"
                    htmlFor="session-venue-select"
                    className="block text-sm font-medium text-gray-500 pb-1"
                  >
                    Session Venue
                  </label>
                  <span className="text-sm text-gray-300">Required</span>
                </div>
                <Select
                  classNamePrefix="session-venue-select"
                  inputId="session-venue-select"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={sessionVenueOptions}
                  value={{
                    value: uploadSessionFormValues.sessionVenue.value,
                    label: uploadSessionFormValues.sessionVenue.label,
                  }}
                  onChange={(sessionVenue) => {
                    if (sessionVenue) {
                      dispatch(
                        storeUploadSessionFormState({
                          ...uploadSessionFormValues,
                          sessionVenue: sessionVenue,
                        }),
                      );
                    }
                  }}
                  isSearchable={false}
                  styles={customSelectStyles}
                  components={{
                    DropdownIndicator: CustomDropdownWithChevron,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div
              className="flex flex-col mt-8 sm:mt-0 sm:w-5/12 space-y-8"
              data-testid="movement-form-fields-container"
            >
              <div data-testid="movement-type-container">
                <div className="flex justify-between">
                  <label
                    id="movement-type-label"
                    htmlFor="movement-type-select"
                    className="block text-sm font-medium text-gray-500 pb-1"
                  >
                    Movement Type
                  </label>
                  <span className="text-sm text-gray-300">Required</span>
                </div>
                <Select
                  aria-labelledby="movement-type-label"
                  classNamePrefix="movement-type-select"
                  inputId="movement-type-select"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={movementTypeOptions}
                  value={{
                    value: uploadSessionFormValues.movementType.value,
                    label: uploadSessionFormValues.movementType.label,
                  }}
                  onChange={(movementType) => {
                    if (movementType) {
                      dispatch(
                        storeUploadSessionFormState({
                          ...uploadSessionFormValues,
                          movementType: movementType,
                          movementSubType: { value: "", label: "" },
                        }),
                      );
                    }
                  }}
                  isSearchable={false}
                  styles={customSelectStyles}
                  components={{
                    DropdownIndicator: CustomDropdownWithChevron,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div data-testid="movement-tags-container">
                <div className="flex justify-between">
                  <label
                    id="movement-tags-label"
                    htmlFor="movement-tags-select"
                    className="block text-sm font-medium text-gray-500 pb-1"
                  >
                    Movement Tags
                  </label>
                  <span className="text-sm text-gray-300">Optional</span>
                </div>
                <Select
                  aria-labelledby="movement-tags-label"
                  classNamePrefix="movement-tags-select"
                  inputId="movement-tags-select"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  isMulti
                  value={uploadSessionFormValues.movementTags.map(
                    (movement: SelectField) => {
                      return { value: movement.value, label: movement.label };
                    },
                  )}
                  isDisabled={uploadSessionFormValues.movementType.value === ""}
                  onChange={(movementTag) => {
                    handleMultiSelect(movementTag);
                  }}
                  options={movementTagsOptions}
                  isSearchable={false}
                  styles={customSelectStyles}
                  components={{
                    DropdownIndicator: CustomDropdownWithChevron,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  );
};
