import { Field, Formik } from "formik";
import React from "react";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { useAppDispatch } from "../../redux/hooks";
import { userSlice } from "../../redux/slices/userSlice";
import { useCreateUserMutation } from "../../services/user";
import { regexForForeignLettersAndSpecialChars } from "../../shared/Constants";
import { ErrorToast, PrimaryButton } from "../shared";

export type RegistrationFormProps = {
  email: string;
  orgId: string;
  token: string;
};

export const RegistrationForm: React.FC<RegistrationFormProps> = ({
  email,
  orgId,
  token,
}: RegistrationFormProps) => {
  const [createUser] = useCreateUserMutation();
  const { setCredentials } = userSlice.actions;
  const dispatch = useAppDispatch();
  const { push } = useHistory();

  const toastError = (message: string) =>
    toast.custom(
      <ErrorToast message={message} classNames={"mt-modal"} support={true} />,
      {
        id: "errorCreatingAccount",
        duration: 6000,
      },
    );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: "",
        lastName: "",
        email: email,
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
          .matches(
            regexForForeignLettersAndSpecialChars,
            "Please enter a valid name",
          )
          .required(""),
        lastName: Yup.string()
          .matches(
            regexForForeignLettersAndSpecialChars,
            "Please enter a valid name",
          )
          .required(""),
        email: Yup.string(),
        password: Yup.string()
          .matches(
            /^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Password must contain at least 8 characters, one letter, one number, and one special character",
          )
          .required(""),
        passwordConfirmation: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required(""),
      })}
      onSubmit={async (values, { resetForm }) => {
        const apiData = {
          registrationFormValues: values,
          orgId: orgId,
          registrationToken: token,
        };
        resetForm();
        createUser(apiData)
          .unwrap()
          .then((response) => {
            dispatch(setCredentials(response));
            push("/");
          })
          .catch(() => {
            toastError("Unable to create account.");
          });
      }}
    >
      {({
        values,
        handleSubmit,
        touched,
        errors,
        dirty,
        isValid,
        setFieldTouched,
      }) => (
        <form
          className="mt-2 sm:mx-auto sm:w-full sm:max-w-md"
          data-testid="registration-form"
          onSubmit={handleSubmit}
        >
          <fieldset className="bg-white py-8 px-4 shadow sm:rounded-lg sm:shadow-lg sm:px-10">
            <div data-testid="first-name-container">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
                data-testid="first-name-label"
              >
                First name <span className="text-red-500 text-xs">*</span>
              </label>
              <div className="mt-1">
                <Field
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={values.firstName}
                  className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm capitalize ${
                    touched.firstName && errors.firstName === ""
                      ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                      : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  }`}
                  data-testid="first-name-input"
                />
                {touched.firstName && errors.firstName ? (
                  <div
                    className="font-medium text-xs text-red-500 pt-1"
                    data-testid="first-name-error"
                  >
                    {errors.firstName}
                  </div>
                ) : null}
              </div>
            </div>

            <div data-testid="last-name-container">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700 pt-4"
                data-testid="last-name-label"
              >
                Last name <span className="text-red-500 text-xs">*</span>
              </label>
              <div className="mt-1">
                <Field
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={values.lastName}
                  className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm capitalize ${
                    touched.lastName && errors.lastName === ""
                      ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                      : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  }`}
                  data-testid="last-name-input"
                />
                {touched.lastName && errors.lastName ? (
                  <div
                    className="font-medium text-xs text-red-500 pt-1"
                    data-testid="last-name-error"
                  >
                    {errors.lastName}
                  </div>
                ) : null}
              </div>
            </div>

            <div data-testid="email-container">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 pt-4"
                data-testid="email-label"
              >
                Email address
              </label>
              <div className="mt-1">
                <Field
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm cursor-not-allowed text-gray-400 sm:text-sm"
                  data-testid="email-input"
                  disabled
                />
              </div>
            </div>

            <div data-testid="password-container">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700 pt-4"
                data-testid="password-label"
              >
                Password <span className="text-red-500 text-xs">*</span>
              </label>
              <div className="mt-1">
                <Field
                  type="password"
                  id="password"
                  name="password"
                  value={values.password}
                  className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm ${
                    touched.password &&
                    (errors.password || errors.password === "")
                      ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                      : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  }`}
                  data-testid="password-input"
                  onInput={() => setFieldTouched("password", true, true)}
                />
                {touched.password && errors.password ? (
                  <div
                    className="font-medium text-xs text-red-500 pt-1"
                    data-testid="password-error"
                  >
                    {errors.password}
                  </div>
                ) : null}
                <p
                  className={`text-xs pt-1 ${
                    touched.password && errors.password === ""
                      ? "text-red-500"
                      : "text-gray-400"
                  } ${
                    errors.password ==
                    "Password must contain at least 8 characters, one letter, one number, and one special character"
                      ? "hidden"
                      : ""
                  }`}
                >
                  Password must contain at least 8 characters, one letter, one
                  number, and one special character
                </p>
              </div>
            </div>

            <div data-testid="password-confirmation-container">
              <label
                htmlFor="passwordConfirmation"
                className="block text-sm font-medium text-gray-700 pt-4"
                data-testid="password-confirmation-label"
              >
                Confirm password <span className="text-red-500 text-xs">*</span>
              </label>
              <div className="mt-1">
                <Field
                  type="password"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  value={values.passwordConfirmation}
                  className={`appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm capitalize ${
                    touched.passwordConfirmation &&
                    (errors.passwordConfirmation ||
                      errors.passwordConfirmation === "")
                      ? "border-red-600 focus:ring-red-600 focus:border-red-600"
                      : "border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                  }`}
                  data-testid="password-confirmation-input"
                  onInput={() =>
                    setFieldTouched("passwordConfirmation", true, true)
                  }
                />
                {touched.passwordConfirmation && errors.passwordConfirmation ? (
                  <div
                    className="font-medium text-xs text-red-500 pt-1"
                    data-testid="password-confirmation-error"
                  >
                    {errors.passwordConfirmation}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="pt-2">
              <PrimaryButton
                className="w-full flex justify-center py-2 px-4 mt-4"
                data-testid="create-account-button"
                disabled={!(dirty && isValid)}
                type="submit"
              >
                Create Account
              </PrimaryButton>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  );
};
