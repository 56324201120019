import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  CustomRow,
  RequestedAnalysesFilter,
  SearchBar,
  TableButton,
  TableHeader,
} from "..";
import {
  ButtonDetails,
  ButtonDetailsWithDropdown,
} from "../../types/sharedTypes";
import {
  filterSessionDates,
  SessionsTableFilter,
} from "../SessionsTableFilter/SessionsTableFilter";

export type TableProps = {
  columns: any[];
  data: Record<string, unknown>[];
  selectedPageSize: string;
  setSelectedPageSize?: (pageSize: string) => void;
  displayFooter: boolean;
  headerRowClassName?: string;
  headerCellClassName?: string;
  customRowClassName?: string;
  customRowCellClassName?: string;
  fetching: boolean;
  hideSearch?: boolean;
  apiPage?: boolean;
  component?: string;
  buttons?: ButtonDetails[] | ButtonDetailsWithDropdown[];
  disableButton?: boolean;
  filters?: string[];
};

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  selectedPageSize,
  setSelectedPageSize,
  displayFooter,
  headerRowClassName,
  headerCellClassName = "",
  customRowClassName,
  customRowCellClassName,
  fetching,
  hideSearch,
  apiPage,
  component,
  buttons,
  disableButton,
  filters,
}) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const {
    nextPage,
    previousPage,
    getCanNextPage,
    getCanPreviousPage,
    setPageIndex,
    getPageCount,
    setPageSize,
    getState,
    setGlobalFilter,
    getHeaderGroups,
    getRowModel,
    getRowCount,
  } = useReactTable({
    columns,
    data,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: parseInt(selectedPageSize),
      },
    },
    filterFns: {
      filterSessionDates,
    },
    state: {
      columnFilters,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
  });

  const pageIndex = getState().pagination.pageIndex;
  const pageSize = getState().pagination.pageSize;
  const globalFilter = getState().globalFilter;
  const rowCount = getRowCount();

  const location = useLocation();

  const primaryButton = buttons?.find((button) => button.type === "primary");

  const secondaryButton = buttons?.find(
    (button) => button.type === "secondary",
  );

  const headerGroups = getHeaderGroups().map((headerGroup) => {
    return headerGroup.headers.map((header) => {
      return header;
    });
  })[0];

  const statusHeader = headerGroups.find((header) => header.id === "status");

  return (
    <div className="max-w-8xl mx-auto w-full pb-10">
      {!hideSearch &&
        (location.pathname === "/analyze" ? (
          <div
            className="flex items-center pb-5 mr-8"
            data-testid="analyze-search-container"
          >
            <div className="flex w-full justify-start">
              <RequestedAnalysesFilter
                column={statusHeader ? statusHeader.column : undefined}
              />
              <SearchBar
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>
            {component && buttons && (
              <div className="flex w-full justify-end">
                {primaryButton && secondaryButton ? (
                  <div className="flex">
                    <TableButton
                      component={component}
                      buttonDetails={primaryButton}
                      disableButton={disableButton}
                    />
                    <TableButton
                      component={component}
                      buttonDetails={secondaryButton}
                      disableButton={disableButton}
                    />
                  </div>
                ) : (
                  <TableButton
                    component={component}
                    buttonDetails={buttons[0]}
                    disableButton={disableButton}
                  />
                )}
              </div>
            )}
          </div>
        ) : filters?.length ? (
          <div
            className="flex justify-between items-center mb-5 mr-8"
            data-testid="search-container-with-filters"
          >
            <div
              className="flex w-full justify-start"
              data-testid="session-filter-container"
            >
              <SearchBar
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
              <SessionsTableFilter
                headers={headerGroups}
                filters={filters ?? []}
              />
            </div>
            {component && buttons && (
              <div
                className="w-60 flex justify-end"
                data-testid="export-data-button-container"
              >
                <TableButton
                  component={component}
                  buttonDetails={buttons[0]}
                  disableButton={disableButton}
                />
              </div>
            )}
          </div>
        ) : (
          <div
            className="flex justify-between items-center mb-5 mr-8"
            data-testid="standard-search-container"
          >
            <SearchBar
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            {component && buttons && (
              <TableButton
                component={component}
                buttonDetails={buttons[0]}
                disableButton={disableButton}
              />
            )}
          </div>
        ))}
      <div className="flex flex-col pt-3 mx-8">
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden border border-gray-200 rounded-lg">
          <table className="w-full">
            <TableHeader
              headerGroups={getHeaderGroups()}
              headerRowClassName={headerRowClassName}
              headerCellClassName={headerCellClassName}
            />
            <CustomRow
              getRowModel={getRowModel()}
              customRowClassName={customRowClassName}
              customRowCellClassName={customRowCellClassName}
            />
          </table>
          {!fetching && !getRowModel().rows.length && (
            <div className="py-4" data-testid="default-message-container">
              <p
                className="text-center text-gray-500"
                data-testid="empty-table-message"
              >
                {apiPage ? "No API Key Generated" : "No information found"}
              </p>
            </div>
          )}
          {getPageCount() > 1 && displayFooter && (
            <footer
              className="bg-gray-50 flex justify-between px-6 border-t border-gray-200"
              data-testid="table-footer"
            >
              <div
                className="flex w-1/2 py-3 items-center"
                data-testid="results-container"
              >
                <p
                  className="font-medium text-base text-gray-500 tracking-wide"
                  data-testid="showing-results-text"
                >
                  {`Showing ${pageIndex * pageSize + 1} to ${
                    pageIndex === getPageCount() - 1
                      ? rowCount
                      : pageIndex * pageSize + pageSize
                  } of ${rowCount} results`}
                </p>
              </div>
              <div
                className="py-3 pr-2 w-1/2 flex justify-end items-center"
                data-testid="pagination-container"
              >
                <select
                  value={pageSize}
                  onChange={(event) => {
                    setPageSize(parseInt(event.target.value));
                    setSelectedPageSize
                      ? setSelectedPageSize(event.target.value)
                      : null;
                  }}
                  className="font-medium text-base text-gray-500 block h-9 pl-3 py-0.5 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md mr-4"
                  data-testid="page-size-select"
                >
                  {[10, 20, 30, 40, 50].map((number) => (
                    <option
                      key={number}
                      value={number}
                      data-testid="page-size-option"
                    >
                      {number} per page
                    </option>
                  ))}
                </select>
                <button
                  onClick={() => setPageIndex(0)}
                  disabled={!getCanPreviousPage()}
                  className="font-medium text-base text-gray-500 tracking-wide pr-2"
                  data-testid="first-page-button"
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => previousPage()}
                  disabled={!getCanPreviousPage()}
                  className="font-medium text-base text-gray-500 tracking-wide pr-1"
                  data-testid="previous-page-button"
                >
                  {"<"}
                </button>
                <p
                  className="py-3 pl-2 pr-2 font-medium text-base text-gray-500 tracking-wide"
                  data-testid="result-counter-text"
                >
                  Page {pageIndex + 1} of {getPageCount()}
                </p>
                <button
                  onClick={() => nextPage()}
                  disabled={!getCanNextPage()}
                  className="font-medium text-base text-gray-500 tracking-wide pl-1"
                  data-testid="next-page-button"
                >
                  {">"}
                </button>
                <button
                  onClick={() => setPageIndex(getPageCount() - 1)}
                  disabled={!getCanNextPage()}
                  className="font-medium text-base text-gray-500 tracking-wide pl-2"
                  data-testid="last-page-button"
                >
                  {">>"}
                </button>
              </div>
            </footer>
          )}
        </div>
      </div>
    </div>
  );
};
