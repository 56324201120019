import { Square2StackIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import { useAppContext } from "../../context";
import { BlankButton, SuccessToast } from "../shared";

type ApiKeyModalProps = {
  apiKey: string;
};

export const ApiKeyModal: React.FC<ApiKeyModalProps> = ({
  apiKey,
}: ApiKeyModalProps) => {
  const { setModalState } = useAppContext();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalXButton, setModalXButton] = useState<HTMLButtonElement | null>(
    null,
  );

  useEffect(() => {
    const button = document.querySelector(
      "[data-testid=custom-modal-close-button]",
    ) as HTMLButtonElement;
    setModalXButton(button);
  }, []);

  const successToast = (message: string) =>
    toast.custom(
      <SuccessToast message={message} classNames="mt-modal w-1/3" />,
    );

  const copyApiKey = () => {
    navigator.clipboard.writeText(apiKey);
    successToast("Successfully Copied!");
    setButtonDisabled(false);
  };

  useEffect(() => {
    if (buttonDisabled && modalXButton) {
      modalXButton.classList.add("pointer-events-none");
    } else {
      modalXButton?.classList.remove("pointer-events-none");
    }
  }, [buttonDisabled, modalXButton]);

  return (
    <div data-testid="api-key-modal-container">
      <div className="flex flex-col items-center p-6">
        <div className="max-w-xl my-24 pb-32">
          <p className="text-gray-500 pb-4" data-testid="api-key-text">
            This API Key is unique and non-recoverable. Please copy the key and
            store it in a safe place. If you lose this API Key, you will need to
            deactivate it and generate a new one.
          </p>
          <div className="w-full flex mt-8">
            <label
              htmlFor="api-key"
              className="sr-only"
              id="screen-reader-api-key-label"
              aria-hidden="true"
            >
              API Key
            </label>
            <input
              type="text"
              id="api-key"
              name="api-key"
              value={apiKey}
              readOnly
              className="border-gray-300 rounded-md w-4/5 text-gray-900"
              data-testid="api-key-input"
            />
            <button
              data-testid="copy-button"
              className="border border-gray-300 rounded-md p-2 ml-2 text-gray-700 text-sm font-bold hover:bg-gray-50"
              onClick={copyApiKey}
            >
              <div className="flex place-items-center">
                <Square2StackIcon className="w-6" data-testid="copy-icon" />
                <p className="ml-1">Copy</p>
              </div>
            </button>
          </div>
        </div>
      </div>
      <footer
        data-testid="api-key-modal-footer"
        className="flex justify-end mb-5 pt-5 pr-5 w-full border-t border-gray-200"
      >
        <BlankButton
          data-testid="api-key-modal-close-button"
          type="button"
          onClick={() => {
            setModalState(false);
            setButtonDisabled(true);
          }}
          disabled={buttonDisabled}
        >
          Close
        </BlankButton>
      </footer>
    </div>
  );
};
