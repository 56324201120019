import { Row } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { MultipleReportsModal, Table } from "../../components";
import {
  ColumnOneTableCellSkeleton,
  SessionDetailsHeaderSkeleton,
  TableCellSkeleton,
} from "../../components/shared";
import { useAppContext } from "../../context";
import { useAppSelector } from "../../redux/hooks";
import {
  useGetReportsBySessionIdQuery,
  useGetSessionByIdQuery,
} from "../../services";
import {
  columnHelper,
  SharedSessionColumns,
} from "../../shared/columns/SharedSessionColumns";
import { AnalysisCell } from "../../shared/Constants";
import {
  displayMovementCount,
  parseDataForSessionDetails,
  showPlayerHandedness,
  toTitleCase,
} from "../../shared/Functions";

type SessionRouteParams = {
  sessionId: string;
};

export const SessionDetails: React.FC = (): JSX.Element => {
  const sessionRouteParams = useParams<SessionRouteParams>();
  const sessionId = sessionRouteParams.sessionId;

  // State and AppContext
  const { setDisplayBack, setModalState, setModalTitle, setModalSingleStep } =
    useAppContext();
  const [selectedPageSize, setSelectedPageSize] = useState(
    sessionStorage.getItem("sessionPageSize") || "10",
  );

  // Redux calls
  const orgType = useAppSelector((state) => state.user).org.type;
  const movementTypes = useAppSelector(
    (state) => state.org,
  ).details.movementTypes.map((type) => type.slug);

  // RTK Queries
  const { data: selectedSession, isFetching: fetchingSessionDetails } =
    useGetSessionByIdQuery(sessionId);
  const { data: sessionReports, isFetching: fetchingReports } =
    useGetReportsBySessionIdQuery({ sessionId, movementTypes });

  const playersFromSelectedSession = selectedSession
    ? selectedSession.players
    : [];

  const sessionDetailsData = parseDataForSessionDetails(
    playersFromSelectedSession,
    selectedSession,
    sessionReports,
  );

  const memoizedSharedSessionColumns = useMemo(SharedSessionColumns, []);

  const sessionDetailColumns = useMemo(
    () => [
      columnHelper.accessor("playerName", {
        header: "Player",
        cell: function playerNameCell(element: any) {
          const playerName = element.getValue();
          return (
            <Link
              to={`/player/${element.row.original.orgPlayerId}`}
              className="font-bold text-primary-700 hover:underline"
              data-testid="player-link"
            >
              {playerName ? toTitleCase(playerName) : ""}
            </Link>
          );
        },
      }),
      memoizedSharedSessionColumns[3],
      memoizedSharedSessionColumns[4],
      memoizedSharedSessionColumns[5],
      columnHelper.accessor("hand", {
        header: "Hand",
        sortingFn: (rowA: Row<any>, rowB: Row<any>) => {
          const handsRowA = rowA.original.hand.split(", ");
          const handsRowB = rowB.original.hand.split(", ");
          if (handsRowA[0] > handsRowB[0]) {
            return 1;
          }
          if (handsRowB[0] > handsRowA[0]) {
            return -1;
          }
          return 0;
        },
        sortDescFirst: false,
        cell: function handCell(element: any) {
          return <p data-testid="hand-cell">{element.getValue()}</p>;
        },
      }),
      columnHelper.accessor("analysis", {
        header: "View Analysis",
        enableSorting: false,
        cell: function analysisCell(element: any) {
          return (
            <>
              <AnalysisCell
                element={element}
                fetchingReports={fetchingReports}
                session={selectedSession}
                openReportsModal={viewSessionReports}
              />
            </>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchingReports, memoizedSharedSessionColumns, selectedSession],
  );

  const columnsWithoutHand = useMemo(
    () =>
      sessionDetailColumns.filter((column) => column.accessorKey !== "hand"),
    [sessionDetailColumns],
  );

  const tableData = useMemo(
    () => (fetchingSessionDetails ? Array(10).fill({}) : sessionDetailsData),
    [fetchingSessionDetails, sessionDetailsData],
  );

  const tableColumns = useMemo(
    () =>
      fetchingSessionDetails
        ? (!showPlayerHandedness(orgType)
            ? columnsWithoutHand
            : sessionDetailColumns
          ).map((column, index) => ({
            ...column,
            cell:
              index === 0 ? (
                <ColumnOneTableCellSkeleton />
              ) : (
                <TableCellSkeleton />
              ),
          }))
        : !showPlayerHandedness(orgType)
          ? columnsWithoutHand
          : sessionDetailColumns,
    [columnsWithoutHand, fetchingSessionDetails, orgType, sessionDetailColumns],
  );

  const viewSessionReports = (player: any, session: any) => {
    const numberOfMovements = displayMovementCount(
      player.numberOfMovements,
      true,
    );
    setModalTitle(
      `${format(parseISO(session.sessionDate), "M/d/yyyy")} - ${
        session.sessionType.name
      } - ${numberOfMovements}`,
    );
    setModalState(true);
    setModalSingleStep(<MultipleReportsModal reports={player.reports} />);
  };

  useEffect(() => {
    setDisplayBack && setDisplayBack(true);
  }, [setDisplayBack]);

  return (
    <main className="relative pb-20 px-4 z-0 overflow-y-auto w-full">
      {fetchingSessionDetails && <SessionDetailsHeaderSkeleton />}
      {selectedSession && (
        <div
          className="flex flex-col justify-start mt-8 max-w-7xl px-4"
          data-testid="session-details-header"
        >
          <p
            className="text-base px-4 text-gray-400 font-bold"
            data-testid="session-date"
          >
            {format(parseISO(selectedSession.sessionDate), "M/d/yyyy")}
          </p>
          <h2
            className="max-w-7xl mt-8 px-4 pl-4 text-3xl leading-6 font-bold text-gray-900"
            data-testid="session-type"
          >
            {selectedSession.sessionType.name}
          </h2>
          <p
            className="text-base px-4 mt-8 text-gray-400 font-bold"
            data-testid="session-venue"
          >
            {selectedSession.sessionVenue !== null
              ? selectedSession.sessionVenue
              : "Default Venue"}
          </p>
        </div>
      )}
      <div
        className="block min-w-max mt-8"
        data-testid="session-details-table-container"
      >
        <Table
          columns={tableColumns}
          data={tableData}
          data-testid="session-details-table"
          selectedPageSize={selectedPageSize}
          setSelectedPageSize={setSelectedPageSize}
          displayFooter={true}
          fetching={fetchingSessionDetails}
          customRowClassName="session-details-row"
        />
      </div>
    </main>
  );
};
