export type FileFormValues = {
  fileChunks?: File[];
  fileId: number;
  fileSizeBytes: number;
  rawFileName: string;
  s3Path?: string;
  status: string;
  sessionFileId?: number;
  numberOfChunks?: number;
};

export type SelectField = {
  value: string;
  label: string;
};

export type UploadSessionFormValues = {
  selectedPlayer: SelectField;
  sessionDate: string;
  sessionType: SelectField;
  sessionVenue: SelectField;
  movementType: SelectField;
  movementSubType: SelectField;
  movementTags: SelectField[];
  files: FileFormValues[];
  orgId: string;
  firstDrop: boolean;
  frameRate: string;
};

export type PlayerFormValues = {
  firstName: string;
  middleName: string | undefined;
  lastName: string;
  dateOfBirth: Date | null;
  throws: SelectField;
  hits: SelectField;
  heightFeet: string;
  heightInches: string;
  weight: string;
  orgPlayerId: string;
  officialLeagueId: string;
};

export enum FormTypeEnum {
  UploadSessionForm = "UPLOADSESSIONFORM",
  PlayerEditForm = "PLAYEREDITFORM",
  NewPlayerForm = "NEWPLAYERFORM",
}

export type RegistrationFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
};
