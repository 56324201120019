import React, { useRef } from "react";

import { Step, StepperProgress, StepType } from "../../components";
import { useAppContext } from "../../context";

export type StepperProps = {
  /** Optional parameter for when the page utilizing the modal needs multiple steps */
  steps?: StepType[];
  /** Optional parameter for when the page utilizing the modal needs a single step */
  step?: JSX.Element;
};

export const Stepper: React.FC<StepperProps> = ({ steps, step }) => {
  const { currentStep, setCurrentStep } = useAppContext();

  const stepperSelector = useRef<HTMLDivElement>(null);

  const goNextStep = () => {
    const nextStep = currentStep + 1;
    if (steps && nextStep <= steps.length) {
      setCurrentStep(nextStep);
    }
  };

  const goPreviousStep = () => {
    const previousStep = currentStep - 1;
    if (previousStep >= 1) {
      setCurrentStep(previousStep);
    }
  };

  return (
    <>
      {steps && steps.length > 0 && (
        <div className="h-full w-full relative overflow-hidden inline-block">
          <StepperProgress
            currentStep={currentStep}
            stepTitles={steps.map((mappedStep) => mappedStep.title)}
          />
          <div
            className="w-full h-full flex justify-center"
            ref={stepperSelector}
            data-testid="steps-container"
          >
            {currentStep === 1 && (
              <Step
                goNextStep={goNextStep}
                goPreviousStep={goPreviousStep}
                currentStep={currentStep}
                isFirst={true}
                isLast={false}
                step={1}
                display={steps[0].display}
                disableNext={steps[0].disableNext}
              />
            )}
            {currentStep === 2 && (
              <Step
                goNextStep={goNextStep}
                goPreviousStep={goPreviousStep}
                currentStep={currentStep}
                isFirst={false}
                isLast={steps.length === 2 ? true : false}
                step={2}
                display={steps[1].display}
                disableNext={steps[1].disableNext}
              />
            )}
            {currentStep === 3 && (
              <Step
                goNextStep={goNextStep}
                goPreviousStep={goPreviousStep}
                currentStep={currentStep}
                isFirst={false}
                isLast={true}
                step={3}
                display={steps[2].display}
                disableNext={steps[2].disableNext}
              />
            )}
          </div>
        </div>
      )}
      {step && <div className="w-full relative mx-auto">{step}</div>}
    </>
  );
};
