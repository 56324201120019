import React from "react";
import { joinClassNames } from "../../../shared/Functions";

type TabProps = {
  page: string;
  title: string;
  activeTab: string;
  toggleTab: (arg0: string) => void;
};

export const TabItem: React.FC<TabProps> = ({
  page,
  title,
  activeTab,
  toggleTab,
}: TabProps): JSX.Element => {
  return (
    <li
      className={joinClassNames(
        activeTab === page
          ? "text-secondary-700 border-secondary-700 cursor-default"
          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer",
        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium",
      )}
      onClick={() => toggleTab(page)}
      data-testid={`${page}-tab`}
    >
      {title}
    </li>
  );
};
