import { format, parse } from "date-fns";
import React, { useCallback, useEffect, useMemo } from "react";

import { StepType } from "..";
import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { uploadSessionFormSlice } from "../../redux/slices/uploadSessionFormSlice";
import { uploadSessionStepList } from "../../shared/Constants";
import {
  disableEditPlayer,
  showPlayerHandedness,
  toTitleCase,
} from "../../shared/Functions";
import { Player, PlayerFormValues } from "../../types";
import { BlankButton, PlayerForm, PrimaryButton } from "../shared";

export interface PlayerHeaderProps {
  player: Player;
}

export const PlayerDetailsHeader: React.FC<PlayerHeaderProps> = ({
  player,
}: PlayerHeaderProps) => {
  // State and AppContext
  const { setModalSingleStep, setModalState, setModalTitle, setModalStepList } =
    useAppContext();

  // Redux calls
  const orgData = useAppSelector((state) => state.org);
  const sessionFormValues = useAppSelector((state) => state.uploadSessionForm);
  const orgType = useAppSelector((state) => state.user).org.type;

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeUploadSessionFormState } = uploadSessionFormSlice.actions;

  const stepList: StepType[] = useMemo((): StepType[] => {
    return uploadSessionStepList({
      playerList: [],
      sessionFormValues: sessionFormValues,
      orgData: orgData,
    }).slice(1);
  }, [orgData, sessionFormValues]);

  const checkIfNextButtonIsEnabled = useCallback(() => {
    if (stepList.length && !stepList[0].disableNext) {
      setModalStepList(stepList);
    }
  }, [setModalStepList, stepList]);

  useEffect(() => {
    checkIfNextButtonIsEnabled();
  }, [checkIfNextButtonIsEnabled]);

  const handleSelectPlayerUpload = () => {
    const selectedPlayerForUpload = {
      value: `${player.id}`,
      label: `${player.firstName} ${player.lastName}`,
    };
    dispatch(
      storeUploadSessionFormState({
        ...sessionFormValues,
        selectedPlayer: selectedPlayerForUpload,
      }),
    );
  };

  const playerBirthDate =
    player.dateOfBirth !== "Unknown"
      ? format(parse(player.dateOfBirth, "M/d/yyyy", new Date()), "yyyy-MM-dd")
      : player.dateOfBirth;

  const editFormValues: PlayerFormValues = {
    firstName: player.firstName,
    middleName: player.middleName ? player.middleName : "",
    lastName: player.lastName,
    dateOfBirth: new Date(`${playerBirthDate}T00:00-0800`),
    throws: { value: player.throws, label: player.throws },
    hits: { value: player.hits, label: player.hits },
    heightFeet: player.height.feet,
    heightInches: player.height.inches,
    weight: player.weight,
    orgPlayerId: player.orgPlayerId,
    officialLeagueId:
      player.officialLeagueId !== null ? player.officialLeagueId : "",
  };

  const openModal = (title: string) => {
    if (title.includes("Edit")) {
      const singleStep = (
        <PlayerForm
          formValues={editFormValues}
          currentOrgPlayerId={player.orgPlayerId}
        />
      );
      setModalSingleStep(singleStep);
      setModalStepList([]);
      setModalTitle(title);
      setModalState(true);
    } else if (title.includes("Upload")) {
      setModalStepList(stepList);
      setModalSingleStep(undefined);
      handleSelectPlayerUpload();
      setModalTitle(title);
      setModalState(true);
    }
  };

  return (
    <div
      className="h-36 w-full px-12 bg-gray-50 flex"
      data-testid="player-details-header"
    >
      <div
        className="h-full w-2/3 flex justify-between text-gray-700 tracking-wider"
        data-testid="player-information"
      >
        <div
          className="flex flex-col justify-center"
          data-testid="name-container"
        >
          <p className="text-4xl font-medium pb-2" data-testid="first-name">
            {toTitleCase(player.firstName)}
          </p>
          <p className="text-4xl font-bold" data-testid="last-name">
            {toTitleCase(player.lastName)}
          </p>
        </div>
        {showPlayerHandedness(orgType) && (
          <>
            <div
              className="flex flex-col justify-center"
              data-testid="throws-container"
            >
              <p
                className="text-xs font-medium uppercase pb-2"
                data-testid="throws-label"
              >
                Throws
              </p>
              <p
                className={`text-xl font-bold ${
                  !player.throws ? "text-red-500 italic" : ""
                }`}
                data-testid="throws"
              >
                {player.throws ? player.throws : "Unknown"}
              </p>
            </div>
            <div
              className="flex flex-col justify-center"
              data-testid="hits-container"
            >
              <p
                className="text-xs font-medium uppercase pb-2"
                data-testid="hits-label"
              >
                Hits
              </p>
              <p
                className={`text-xl font-bold ${
                  !player.hits ? "text-red-500 italic" : ""
                }`}
                data-testid="hits"
              >
                {player.hits ? player.hits : "Unknown"}
              </p>
            </div>
          </>
        )}
        <div
          className="flex flex-col justify-center"
          data-testid="height-container"
        >
          <p
            className="text-xs font-medium uppercase pb-2"
            data-testid="height-label"
          >
            Height
          </p>
          <p
            className={`text-xl font-bold ${
              !player.height.feet ? "text-red-500 italic" : ""
            }`}
            data-testid="height"
          >
            {player.height.feet
              ? player.height.feet + " ft " + player.height.inches + " in"
              : "Unknown"}
          </p>
        </div>
        <div
          className="flex flex-col justify-center"
          data-testid="weight-container"
        >
          <p
            className="text-xs font-medium uppercase pb-2"
            data-testid="weight-label"
          >
            Weight
          </p>
          <p
            className={`text-xl font-bold ${
              !player.weight ? "text-red-500 italic" : ""
            }`}
            data-testid="weight"
          >
            {player.weight ? player.weight + " lbs" : "Unknown"}
          </p>
        </div>
        <div
          className="flex flex-col justify-center"
          data-testid="date-container"
        >
          <p
            className="text-xs font-medium uppercase pb-2"
            data-testid="date-label"
          >
            Birth Date
          </p>
          <p
            className={`text-xl font-bold ${
              player.dateOfBirth === "1/1/1900" ||
              player.dateOfBirth === "Unknown"
                ? "text-red-500 italic"
                : ""
            }`}
            data-testid="date"
          >
            {player.dateOfBirth}
          </p>
        </div>
      </div>
      <div
        className="h-full w-1/3 flex justify-end items-center"
        data-testid="button-container"
      >
        {!disableEditPlayer(orgType) && (
          <BlankButton
            className="h-10 mr-4"
            data-testid="edit-button"
            onClick={() => {
              openModal(`Edit - ${player.firstName} ${player.lastName}`);
            }}
          >
            Edit
          </BlankButton>
        )}
        <PrimaryButton
          className="h-10"
          data-testid="upload-button"
          onClick={() => {
            openModal(`Upload Data - ${player.firstName} ${player.lastName}`);
          }}
        >
          Upload Data
        </PrimaryButton>
      </div>
    </div>
  );
};
