import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React from "react";

import { CustomTooltip, Segment, SegmentDetails } from "..";
import { useAppSelector } from "../../redux/hooks";
import { joinClassNames } from "../../shared/Functions";
import { RequestedAnalysisFormValues } from "../../types";

type PrimarySegmentProps = {
  shouldShowSegments: boolean;
  primarySegmentDefined: boolean;
  setPrimarySegmentDefined: React.Dispatch<React.SetStateAction<boolean>>;
  values: RequestedAnalysisFormValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  editPrimarySegment: boolean;
  setEditPrimarySegment: React.Dispatch<React.SetStateAction<boolean>>;
  isValid: boolean;
  dirty: boolean;
};

export const PrimarySegment: React.FC<PrimarySegmentProps> = ({
  shouldShowSegments,
  primarySegmentDefined,
  setPrimarySegmentDefined,
  values,
  setFieldValue,
  setFieldTouched,
  editPrimarySegment,
  setEditPrimarySegment,
  isValid,
  dirty,
}: PrimarySegmentProps): JSX.Element => {
  // Redux calls
  const requestedAnalysisFormState = useAppSelector(
    (state) => state.requestedAnalysisForm,
  );
  const requestedAnalysisState = useAppSelector(
    (state) => state.requestedAnalysis,
  );

  const { primarySegmentData } = requestedAnalysisState;

  return (
    <div
      className={joinClassNames(
        !shouldShowSegments ? "opacity-50 pointer-events-none" : "",
        "block min-w-max mt-3 border border-gray-200 rounded-lg shadow",
      )}
      data-testid="primary-segment-container"
    >
      <div
        className="rounded-t-md px-8 py-4 flex bg-gray-50 w-full justify-between items-center border-b border-b-gray-200"
        data-testid="define-primary-segment-header"
      >
        <div className="flex">
          <p className="text-gray-900 text-2xl">
            <span
              className={joinClassNames(primarySegmentDefined ? "hidden" : "")}
            >
              Define
            </span>{" "}
            Primary Segment
          </p>
          <InformationCircleIcon
            className="w-6 text-gray-500 ml-5"
            id="tooltip-anchor-primary"
            data-tooltip-id="tooltip-anchor-primary"
          />
          <CustomTooltip
            anchorId={"tooltip-anchor-primary"}
            type="primary"
            info="Defining the Primary Segment is the first step and involves dividing the larger population 
                      into a smaller segment based on characteristics like pitch type or player."
            place={"right"}
          />
        </div>
        {primarySegmentDefined && (
          <div
            className="flex space-x-6"
            data-testid="primary-segment-numbers-container"
          >
            <p className="text-2xl" data-testid="primary-segment-player-count">
              <span className="text-secondary-900">
                {primarySegmentData.numPlayers}
              </span>{" "}
              <span className="text-gray-900">Players</span>
            </p>
            <p
              className="text-2xl"
              data-testid="primary-segment-movement-count"
            >
              <span className="text-secondary-900">
                {primarySegmentData.numMovements}
              </span>{" "}
              <span className="text-gray-900">Movements</span>
            </p>
          </div>
        )}
      </div>
      {!primarySegmentDefined ? (
        <Segment
          values={values}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          segmentType={"primary"}
          movementTypeId={requestedAnalysisFormState.movementType.id}
          setPrimarySegmentDefined={setPrimarySegmentDefined}
          editPrimarySegment={editPrimarySegment}
          setEditPrimarySegment={setEditPrimarySegment}
          isValid={isValid}
          dirty={dirty}
        />
      ) : (
        <SegmentDetails
          segmentType={"primary"}
          setPrimarySegmentDefined={setPrimarySegmentDefined}
          setEditPrimarySegment={setEditPrimarySegment}
        />
      )}
    </div>
  );
};
