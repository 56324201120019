import React from "react";
import { toast } from "react-hot-toast";

import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { uploadSessionFormSlice } from "../../redux/slices/uploadSessionFormSlice";
import { uploadSessionSlice } from "../../redux/slices/uploadSessionSlice";
import {
  usePatchSessionUploadMutation,
  usePostSessionUploadMutation,
} from "../../services/session";
import {
  fileChunkSize,
  uploadFileToS3Bucket,
} from "../../shared/S3UploadFunctions";
import { FormTypeEnum, UploadFileStatusEnum } from "../../types";
import { PostSessionUploadResponse } from "../../types/apiTypes";
import { BlankButton, ErrorToast, PrimaryButton } from "../shared";

export type StepProps = {
  goNextStep: () => void;
  goPreviousStep: () => void;
  currentStep: number;
  isLast: boolean;
  isFirst: boolean;
  step: number;
  display: JSX.Element;
  disableNext: boolean;
};

export type StepType = {
  title: string;
  display: JSX.Element;
  disableNext: boolean;
  formType: FormTypeEnum;
  element: (stepProps: StepProps) => JSX.Element;
};

export const Step: React.FC<StepProps> = ({
  goNextStep,
  goPreviousStep,
  isFirst,
  isLast,
  display,
  disableNext,
  step,
}) => {
  const error = (message: string) =>
    toast.custom(<ErrorToast message={message} classNames={"mt-modal"} />, {
      id: "uploadError",
      duration: 4000,
    });

  // State and AppContext
  const {
    setPopupTitle,
    setPopupState,
    setModalState,
    uploadFiles,
    setCurrentStep,
    setAbortControllers,
    setUploadInProgress,
    setDialogState,
  } = useAppContext();

  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeUploadSessionState } = uploadSessionSlice.actions;
  const { clearUploadSessionFormState } = uploadSessionFormSlice.actions;

  // RTK Queries and Mutations
  const [postSessionUpload, isSuccess] = usePostSessionUploadMutation();
  const [patchSessionUpload] = usePatchSessionUploadMutation();

  const openPopup = (success: boolean) => {
    setModalState(false);
    setCurrentStep(1);
    if (success && uploadSessionFormValues.files.length) {
      const numberOfFiles = uploadSessionFormValues.files.length;
      const filesSingularOrPlural = numberOfFiles === 1 ? "file" : "files";
      setPopupState(true);
      setPopupTitle(`Uploading ${numberOfFiles} ${filesSingularOrPlural}`);
      sessionStorage.setItem("popupState", "true");
      sessionStorage.setItem(
        "popupTitle",
        `Uploading ${numberOfFiles} ${filesSingularOrPlural}`,
      );
    }
  };

  const determineNumberOfChunks = (fileSize: number) => {
    let numberOfChunks = 0;
    if (fileSize % fileChunkSize > 0) {
      numberOfChunks += 1;
    }
    numberOfChunks += Math.floor(fileSize / fileChunkSize);
    return numberOfChunks;
  };

  const useHandleFormSubmit = () => {
    const frameRate = uploadSessionFormValues.frameRate;
    const newUploadSessionFormValues = {
      ...uploadSessionFormValues,
      files: uploadSessionFormValues.files.map((file) => {
        return {
          ...file,
          frameRate: parseInt(frameRate || "240"),
          numberOfChunks: determineNumberOfChunks(file.fileSizeBytes),
        };
      }),
    };
    if (!frameRate || frameRate === "") {
      setDialogState({
        shouldOpen: true,
        type: "frameRate",
      });
    } else {
      postSessionUpload(newUploadSessionFormValues)
        .unwrap()
        .then((response: PostSessionUploadResponse) => {
          if (isSuccess) {
            openPopup(true);
            setUploadInProgress(true);
            return dispatch(
              storeUploadSessionState({
                sessionDate: newUploadSessionFormValues.sessionDate,
                playerId: newUploadSessionFormValues.selectedPlayer.value,
                orgId: newUploadSessionFormValues.orgId,
                uploadSessionId: response.uploadSessionId,
                preSignedUrls: response.preSignedUrls,
                finished: false,
                uploadedFiles: response.preSignedUrls.map(
                  (preSignedUrl: any) => {
                    return {
                      fileId: preSignedUrl.fileId,
                      fileName: preSignedUrl.fileName,
                      sessionFileId: preSignedUrl.sessionFileId,
                      uploadSessionId: response.uploadSessionId,
                      uploadStatus: UploadFileStatusEnum.Pending,
                      playerName:
                        newUploadSessionFormValues.selectedPlayer.label,
                      info: preSignedUrl.info,
                      uploadProgress: 0,
                    };
                  },
                ),
              }),
            );
          }
        })
        .then((response) => {
          if (response) {
            response.payload.preSignedUrls.forEach((preSignedUrlInfo: any) => {
              const controller: AbortController = new AbortController();
              const abortControllerInfo = {
                abortController: controller,
                fileName: preSignedUrlInfo.fileName,
              };
              setAbortControllers((prev) => [...prev, abortControllerInfo]);
              uploadFileToS3Bucket(
                preSignedUrlInfo,
                response.payload.uploadSessionId,
                uploadFiles,
                dispatch,
                patchSessionUpload,
                abortControllerInfo,
              );
            });
          }
        })
        .then(() => {
          dispatch(clearUploadSessionFormState());
        })
        .catch(() => {
          openPopup(false);
          error("There was an issue uploading the session. Please try again.");
          dispatch(clearUploadSessionFormState());
          setUploadInProgress(false);
        });
    }
  };

  return (
    <div className="h-screen sm:h-full w-full flex-col justify-between">
      <section
        className="h-[55%] sm:h-[75%] lg:h-96 flex sm:place-items-center w-full p-4 sm:p-0"
        data-testid="step-container"
      >
        {display}
      </section>
      <footer
        id="footer-container"
        className="flex justify-end pt-5 border-t border-gray-200 h-20 p-4"
        data-testid="modal-footer"
      >
        <BlankButton
          data-testid={`back-modal-button-${step}`}
          hidden={isFirst}
          onClick={goPreviousStep}
        >
          Back
        </BlankButton>
        <PrimaryButton
          onClick={isLast ? useHandleFormSubmit : goNextStep}
          data-testid={`next-modal-button-${step}`}
          className="ml-3"
          disabled={disableNext}
        >
          {isLast ? "Upload" : "Next"}
        </PrimaryButton>
      </footer>
    </div>
  );
};
