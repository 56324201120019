import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import CreatableSelect from "react-select/creatable";

import { useAppContext } from "../../context";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { uploadSessionFormSlice } from "../../redux/slices/uploadSessionFormSlice";
import {
  customSelectStyles,
  emptyPlayerFormValues,
} from "../../shared/Constants";
import { disableAddPlayer, toTitleCase } from "../../shared/Functions";
import { Player, PlayerFormValues } from "../../types";
import { CustomDropdownWithChevron, PlayerForm } from "../shared";

export type SelectPlayerUploadProps = {
  players: Player[];
};

export const SelectPlayerUpload: React.FC<SelectPlayerUploadProps> = ({
  players,
}) => {
  // State and AppContext
  const [, setIsMenuOpen] = useState(false);
  const { setModalSingleStep, setModalState, setModalTitle, setModalStepList } =
    useAppContext();

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeUploadSessionFormState } = uploadSessionFormSlice.actions;

  // Redux calls
  const uploadSessionFormValues = useAppSelector(
    (state) => state.uploadSessionForm,
  );
  const orgType = useAppSelector((state) => state.user).org.type;

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  const playerOptions = players.map((player) => {
    const name = toTitleCase(`${player.firstName} ${player.lastName}`);
    return { value: `${player.id}`, label: name };
  });
  const stepTitleNode = document.querySelector("#step-1-title");

  const openModal = (title: string, playerForm?: PlayerFormValues) => {
    setModalStepList([]);
    const step = (
      <PlayerForm
        formValues={playerForm ? playerForm : emptyPlayerFormValues}
      />
    );
    setModalSingleStep(step);
    setModalTitle(title);
    setModalState(true);
  };

  function handlePlayerChange(
    player: { value: string; label: string; __isNew__?: boolean } | null,
  ): void {
    if (player && !player["__isNew__"]) {
      if (stepTitleNode) {
        stepTitleNode.innerHTML = `
          <div class="hidden sm:flex">Select Player</div>
          <div class="text-gray-700 font-bold text-[12px] sm:text-sm">${
            player.label.split(" ")[0]
          } ${player.label.split(" ")[1]}</div>`;
      }
      dispatch(
        storeUploadSessionFormState({
          ...uploadSessionFormValues,
          selectedPlayer: player,
        }),
      );
    } else if (player && player["__isNew__"] && isMobile) {
      return;
    } else if (!disableAddPlayer(orgType)) {
      setModalState(false);
      openModal("Add New Player");
    }
  }

  return (
    <div
      className="flex-col w-full sm:w-2/6 mx-auto"
      data-testid="select-player-container"
    >
      <div className="h-16 mb-6">
        <div className="flex justify-between">
          <label
            id="player-select-label"
            htmlFor="player-select"
            className="block text-sm font-medium text-gray-500 pb-1"
          >
            Player
          </label>
          <span className="text-sm text-gray-300" data-testid="required-text">
            Required
          </span>
        </div>
        <CreatableSelect
          aria-labelledby="player-select-label"
          inputId="player-select-input"
          classNamePrefix="player-select"
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          value={uploadSessionFormValues.selectedPlayer}
          options={playerOptions}
          onChange={(player) => {
            handlePlayerChange(player);
          }}
          formatCreateLabel={(): string => {
            if (disableAddPlayer(orgType) || isMobile) {
              return "Player Not Found";
            } else {
              return "+ Create New Player";
            }
          }}
          styles={customSelectStyles}
          components={{
            DropdownIndicator: CustomDropdownWithChevron,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </div>
  );
};
