import { PlusIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import { SegmentCriteria, SegmentDateRange } from "..";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { requestedAnalysisFormSlice } from "../../redux/slices/requestedAnalysisFormSlice";
import { requestedAnalysisSlice } from "../../redux/slices/requestedAnalysisSlice";
import {
  useCreatePlayerGroupSegmentMutation,
  useUpdatePlayerGroupSegmentMutation,
} from "../../services";
import { joinClassNames } from "../../shared/Functions";
import {
  PlayerGroupSegmentCriteria,
  RequestedAnalysisFormValues,
  SegmentDetails,
  SelectField,
} from "../../types";
import {
  BlankButton,
  ErrorToast,
  SecondaryButton,
  SpinnerButton,
} from "../shared";

type SegmentProps = {
  values: RequestedAnalysisFormValues;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void;
  segmentType: string;
  movementTypeId: number;
  setPrimarySegmentDefined?: React.Dispatch<React.SetStateAction<boolean>>;
  setPopulationSegmentDefined?: React.Dispatch<React.SetStateAction<boolean>>;
  setComparisonSegmentDefined?: React.Dispatch<React.SetStateAction<boolean>>;
  editPrimarySegment?: boolean;
  setEditPrimarySegment?: React.Dispatch<React.SetStateAction<boolean>>;
  editPopulationSegment?: boolean;
  setEditPopulationSegment?: React.Dispatch<React.SetStateAction<boolean>>;
  editComparisonSegment?: boolean;
  setEditComparisonSegment?: React.Dispatch<React.SetStateAction<boolean>>;
  isValid: boolean;
  dirty: boolean;
};

const emptySegment = {
  dateRange: [""],
  individualDates: [""],
  dateCriteria: { value: "", label: "" },
  criteria: [
    {
      field: { value: "", label: "" },
      comparison: { value: "", label: "" },
      option: { value: "", label: "", id: "" },
    },
  ],
};

export const Segment: React.FC<SegmentProps> = ({
  values,
  setFieldValue,
  setFieldTouched,
  segmentType,
  movementTypeId,
  setPrimarySegmentDefined,
  setPopulationSegmentDefined,
  setComparisonSegmentDefined,
  editPrimarySegment,
  setEditPrimarySegment,
  editPopulationSegment,
  setEditPopulationSegment,
  editComparisonSegment,
  setEditComparisonSegment,
  isValid,
  dirty,
}: SegmentProps): JSX.Element => {
  // State and AppContext
  const [segmentCriteria, setSegmentCriteria] = useState<
    PlayerGroupSegmentCriteria[]
  >([
    {
      field: { value: "", label: "" },
      comparison: { value: "", label: "" },
      option: { value: "", label: "" },
    },
  ]);
  const [currentSegmentState, setCurrentSegmentState] =
    useState<SegmentDetails>(emptySegment);
  const [selectFieldOptions, setSelectFieldOptions] = useState<SelectField[]>(
    [],
  );
  const [disableCriteriaButton, setDisableCriteriaButton] =
    useState<boolean>(false);
  const [fieldsSelected, setFieldsSelected] = useState<string[]>([]);

  // RTK slice actions
  const dispatch = useAppDispatch();
  const { storeRequestedAnalysisFormState } =
    requestedAnalysisFormSlice.actions;
  const { storeRequestedAnalysisState } = requestedAnalysisSlice.actions;

  // Redux calls
  const requestedAnalysisFormState = useAppSelector(
    (state) => state.requestedAnalysisForm,
  );

  const requestedAnalysisState = useAppSelector(
    (state) => state.requestedAnalysis,
  );

  // RTK Mutations
  const [createPlayerGroupSegment, { isLoading }] =
    useCreatePlayerGroupSegmentMutation();
  const [updatePlayerGroupSegment, { isLoading: updatingSegment }] =
    useUpdatePlayerGroupSegmentMutation();

  const errorToast = (message: string) =>
    toast.custom(
      <ErrorToast message={message} classNames="mt-modal max-w-3xl" />,
      {
        id: "segmentError",
        duration: 5000,
      },
    );

  useEffect(() => {
    if (segmentType === "primary") {
      setCurrentSegmentState(requestedAnalysisFormState.primarySegment);
    } else if (segmentType === "population") {
      setCurrentSegmentState(requestedAnalysisFormState.populationSegment);
    } else if (segmentType === "comparison") {
      setCurrentSegmentState(requestedAnalysisFormState.comparisonSegment);
    }
  }, [segmentType, values, requestedAnalysisFormState]);

  useEffect(() => {
    if (
      segmentType === "primary" &&
      requestedAnalysisFormState.primarySegment.criteria.length > 0
    ) {
      setSegmentCriteria(requestedAnalysisFormState.primarySegment.criteria);
    } else if (
      segmentType === "population" &&
      requestedAnalysisFormState.populationSegment.criteria.length > 0
    ) {
      setSegmentCriteria(requestedAnalysisFormState.populationSegment.criteria);
    } else if (
      segmentType === "comparison" &&
      requestedAnalysisFormState.comparisonSegment.criteria.length > 0
    ) {
      setSegmentCriteria(requestedAnalysisFormState.comparisonSegment.criteria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentType]);

  const segmentKey: any = Object.keys(requestedAnalysisFormState).find((key) =>
    key.includes(segmentType),
  );

  const addCriteria = () => {
    setSegmentCriteria((criteria) => {
      return [
        ...criteria,
        {
          field: { value: "", label: "" },
          comparison: { value: "", label: "" },
          option: { value: "", label: "" },
        },
      ];
    });
    dispatch(
      storeRequestedAnalysisFormState({
        ...requestedAnalysisFormState,
        [segmentKey]: {
          ...currentSegmentState,
          criteria: [...currentSegmentState.criteria],
        },
      }),
    );
  };

  const clearCriteria = () => {
    dispatch(
      storeRequestedAnalysisFormState({
        ...requestedAnalysisFormState,
        [segmentKey]: {
          dateRange: [""],
          dateCriteria: { value: "", label: "" },
          criteria: [],
        },
      }),
    );
    setSegmentCriteria([
      {
        field: { value: "", label: "" },
        comparison: { value: "", label: "" },
        option: { value: "", label: "" },
      },
    ]);
  };

  const defineSegment = async (segment: string) => {
    let primarySegment;
    let populationSegment;
    let comparisonSegment;
    const details = {
      hand: requestedAnalysisFormState.hand.id,
      movementTypeId: requestedAnalysisFormState.movementType.id,
    };

    if (segment === "primary") {
      if (editPrimarySegment) {
        await updatePlayerGroupSegment({
          id: requestedAnalysisState.primarySegmentData.analysisSegmentId,
          details,
          segmentData: requestedAnalysisFormState.primarySegment,
        })
          .unwrap()
          .then((response) => {
            primarySegment = response;
            setPrimarySegmentDefined?.(true);
            setEditPrimarySegment?.(false);
          })
          .catch((error) => {
            errorToast(
              `There was an error updating the primary segment. ${
                error.data ? error.data.detail : "Please try again."
              }`,
            );
          });
      } else {
        await createPlayerGroupSegment({
          details,
          segmentData: requestedAnalysisFormState.primarySegment,
        })
          .unwrap()
          .then((response) => {
            primarySegment = response;
            setPrimarySegmentDefined?.(true);
          })
          .catch((error) => {
            errorToast(
              `There was an error creating the primary segment. ${
                error.data ? error.data.detail : "Please try again."
              }`,
            );
          });
      }
    } else if (segment === "population") {
      if (editPopulationSegment) {
        await updatePlayerGroupSegment({
          id: requestedAnalysisState.populationSegmentData.analysisSegmentId,
          details,
          segmentData: requestedAnalysisFormState.populationSegment,
        })
          .unwrap()
          .then((response) => {
            populationSegment = response;
            setPopulationSegmentDefined?.(true);
            setEditPopulationSegment?.(false);
          })
          .catch((error) => {
            errorToast(
              `There was an error updating the population segment. ${
                error.data ? error.data.detail : "Please try again."
              }`,
            );
          });
      } else {
        await createPlayerGroupSegment({
          details,
          segmentData: requestedAnalysisFormState.populationSegment,
        })
          .unwrap()
          .then((response) => {
            populationSegment = response;
            setPopulationSegmentDefined?.(true);
          })
          .catch((error) => {
            errorToast(
              `There was an error creating the population segment. ${
                error.data ? error.data.detail : "Please try again."
              }`,
            );
          });
      }
    } else if (segment === "comparison") {
      if (editComparisonSegment) {
        await updatePlayerGroupSegment({
          id: requestedAnalysisState.comparisonSegmentData.analysisSegmentId,
          details,
          segmentData: requestedAnalysisFormState.comparisonSegment,
        })
          .unwrap()
          .then((response) => {
            comparisonSegment = response;
            setComparisonSegmentDefined?.(true);
            setEditComparisonSegment?.(false);
          })
          .catch((error) => {
            errorToast(
              `There was an error updating the comparison segment. ${
                error.data ? error.data.detail : "Please try again."
              }`,
            );
          });
      } else {
        await createPlayerGroupSegment({
          details,
          segmentData: requestedAnalysisFormState.comparisonSegment,
        })
          .unwrap()
          .then((response) => {
            comparisonSegment = response;
            setComparisonSegmentDefined?.(true);
          })
          .catch((error) => {
            errorToast(
              `There was an error creating the comparison segment. ${
                error.data ? error.data.detail : "Please try again."
              }`,
            );
          });
      }
    }

    dispatch(
      storeRequestedAnalysisState({
        ...requestedAnalysisState,
        name: requestedAnalysisFormState.name,
        primarySegmentData: primarySegment
          ? primarySegment
          : requestedAnalysisState.primarySegmentData,
        populationSegmentData: populationSegment
          ? populationSegment
          : requestedAnalysisState.populationSegmentData,
        comparisonSegmentData: comparisonSegment
          ? comparisonSegment
          : requestedAnalysisState.comparisonSegmentData,
      }),
    );
  };

  useEffect(() => {
    if (fieldsSelected.length === 0) {
      setDisableCriteriaButton(true);
    } else if (
      selectFieldOptions.length === 0 &&
      segmentCriteria.length === 0
    ) {
      setDisableCriteriaButton(false);
    } else if (selectFieldOptions.length === 0) {
      setDisableCriteriaButton(true);
    } else if (fieldsSelected.length < segmentCriteria.length) {
      setDisableCriteriaButton(true);
    } else {
      setDisableCriteriaButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentCriteria, selectFieldOptions, fieldsSelected]);

  return (
    <div data-testid={`${segmentType}-segment-container`}>
      <div
        className={joinClassNames(
          isLoading || updatingSegment ? "opacity-50 pointer-events-none" : "",
          "text-gray-500 p-8 rounded-b-md",
        )}
        data-testid={`${segmentType}-segment-body`}
      >
        <SegmentDateRange
          segmentType={segmentType}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          segmentKey={segmentKey}
          currentSegmentState={currentSegmentState}
        />

        <div
          className="pt-3"
          data-testid={`${segmentType}-segment-define-criteria-container`}
        >
          {segmentCriteria.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="flex py-3"
                data-testid={`${segmentType}-segment-criteria-group`}
              >
                <SegmentCriteria
                  index={index}
                  movementTypeId={movementTypeId}
                  segmentType={segmentType}
                  selectFieldOptions={selectFieldOptions}
                  setSelectFieldOptions={setSelectFieldOptions}
                  setFieldValue={setFieldValue}
                  currentSegmentState={currentSegmentState}
                  setSegmentCriteria={setSegmentCriteria}
                  segmentKey={segmentKey}
                  fieldsSelected={fieldsSelected}
                  setFieldsSelected={setFieldsSelected}
                  setFieldTouched={setFieldTouched}
                  isLoading={isLoading || updatingSegment}
                />
              </div>
            );
          })}
          <button
            className={joinClassNames(
              isLoading || updatingSegment
                ? "opacity-50 pointer-events-none"
                : "",
              "flex text-secondary-500 py-4 disabled:cursor-not-allowed disabled:opacity-50",
            )}
            data-testid={`${segmentType}-add-criteria-button`}
            onClick={(event) => {
              event.preventDefault();
              addCriteria();
            }}
            disabled={disableCriteriaButton}
          >
            <PlusIcon className="w-5 mr-4" />
            Add Criteria
          </button>
        </div>
      </div>
      {currentSegmentState.criteria.length > 0 && (
        <div
          className="border-t border-[#E5E7EB]"
          data-testid={`${segmentType}-segment-footer`}
        >
          <div
            className="pl-8 py-4 flex"
            data-testid={`${segmentType}-segment-buttons-container`}
          >
            <BlankButton
              className={joinClassNames(
                isLoading || updatingSegment
                  ? "opacity-50 pointer-events-none"
                  : "",
                "mr-4",
              )}
              data-testid={`${segmentType}-segment-clear-button`}
              type="button"
              onClick={clearCriteria}
            >
              Clear
            </BlankButton>
            {isLoading || updatingSegment ? (
              <div>
                <SpinnerButton btnText={"Processing"} />
              </div>
            ) : (
              <SecondaryButton
                data-testid={`${segmentType}-segment-define-button`}
                onClick={() => defineSegment(segmentType)}
                type="button"
                disabled={!(dirty && isValid)}
              >
                Define
              </SecondaryButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
